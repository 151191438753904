import { formDataPatchAPI, postDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const generateOTP = (data) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI("auth/signin/request", data);

    if (res.data.success) {
      localStorage.setItem("email", data.email);
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
    return Promise.resolve();
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};

export const verifyOTP = (data) => async (dispatch) => {
  try {
    const email = localStorage.getItem("email");
    const payload = { email, otp: data.otp };
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI("auth/signin/verify", payload);
    if (res.data.success) {
      dispatch({
        type: TYPES.AUTH,
        payload: {
          token: res.data.data.token,
          user: res.data.data.user,
          usertype: res.data.data.user.type,
        },
      });
      dispatch({
        type: TYPES.ALERT,
        payload: {
          success: res.data.message,
        },
      });
      localStorage.setItem("token", res.data.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      localStorage.setItem("usertype", res.data.data.user.type);
      localStorage.removeItem("email");
      return Promise.resolve();
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
    return Promise.reject();
  }
};

export const impersonateLogin =
  (userId, user, userType) => async (dispatch, getState) => {
    try {
      const payload = { userId };
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/impersonate/login`,
        payload,
        token
      );

      if (res.data.success) {
        dispatch({
          type: TYPES.AUTH,
          payload: {
            token: res.data.data,
            user,
            usertype: userType,
          },
        });
        dispatch({
          type: TYPES.ALERT,
          payload: {
            success: res.data.message,
          },
        });
        localStorage.setItem("token", res.data.data);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("usertype", userType);
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
      return Promise.reject();
    }
  };

export const logoutUser = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const res = await postDataAPI(`${usertype}/logout`, {}, token);

    if (res.data.success) {
      localStorage.removeItem("email");
      localStorage.removeItem("user");
      localStorage.removeItem("usertype");
      localStorage.removeItem("token");

      dispatch({
        type: TYPES.LOGOUT,
        payload: {
          token: "",
          user: {},
          usertype: "",
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);

    localStorage.removeItem("email");
    localStorage.removeItem("user");
    localStorage.removeItem("usertype");
    localStorage.removeItem("token");
    dispatch({
      type: TYPES.LOGOUT,
      payload: {
        token: "",
        user: {},
        usertype: "",
      },
    });
    dispatch({ type: TYPES.ALERT, payload: { loading: false } });
  }
};

export const editProfile = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });

    const formData = new FormData();
    formData.append("profilePic", payload.profilePic);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);

    const res = await formDataPatchAPI(`${usertype}/me`, formData, token);
    if (res.data.success) {
      dispatch({
        type: TYPES.EDIT_PROFILE,
        payload: res.data.data,
      });
      dispatch({
        type: TYPES.ALERT,
        payload: {
          success: res.data.message,
        },
      });
      localStorage.setItem("user", JSON.stringify(res.data.data));
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
