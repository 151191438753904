import { postDataAPI, getDataAPI, patchDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";
import { STOCK_TYPES } from "./stockAction";

export const ORDER_TYPES = {
  GET_ORDERS: "GET_ORDERS",
  GET_ORDER: "GET_ORDER",
  GET_EXPIRING_LOT_ORDERS: "GET_EXPIRING_LOT_ORDERS",
  DISPATCH_ORDER: "DISPATCH_ORDER",
  COMPLETE_ORDER: "COMPLETE_ORDER",
  APPROVE_ORDER: "APPROVE_ORDER",
  DISPATCH_EXPIRING_LOT_ORDER: "DISPATCH_EXPIRING_LOT_ORDER",
};

export const getOrders =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/orders?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: ORDER_TYPES.GET_ORDERS,
          payload: {
            orders: res.data.data.orders,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getExpiringLotOrders =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/orders/expiring?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: ORDER_TYPES.GET_EXPIRING_LOT_ORDERS,
          payload: {
            orders: res.data.data.orders,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const createOrder = (stockId, order) => async (dispatch, getState) => {
  try {
    if (order.length === 0) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "No stock selected for order",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await postDataAPI(
      `${usertype}/stock/${stockId}/order`,
      { orderUnits: order },
      token
    );

    if (res.data.success) {
      dispatch({
        type: STOCK_TYPES.UPDATE_STOCK,
        payload: { stockId, stock: res.data.data },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      dispatch({
        type: TYPES.ALERT,
        payload: {
          success: res.data.message,
        },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const dispatchOrder =
  (orderId, orderType) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/order/${orderId}/dispatch`,
        {},
        token
      );

      if (res.data.success) {
        if (orderType === "STANDARD")
          dispatch({
            type: ORDER_TYPES.DISPATCH_ORDER,
            payload: { orderId, order: res.data.data },
          });
        else if (orderType === "EXPIRING")
          dispatch({
            type: ORDER_TYPES.DISPATCH_EXPIRING_LOT_ORDER,
            payload: { orderId, order: res.data.data },
          });

        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const completeOrder = (orderId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/order/${orderId}/complete`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: ORDER_TYPES.COMPLETE_ORDER,
        payload: { orderId, order: res.data.data },
      });

      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const approveOrder = (orderId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await patchDataAPI(
      `${usertype}/order/${orderId}/approve`,
      {},
      token
    );

    if (res.data.success) {
      dispatch({
        type: ORDER_TYPES.APPROVE_ORDER,
        payload: { orderId, order: res.data.data },
      });

      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const getOrder = (orderId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/order/${orderId}`, token);

    if (res.data.success) {
      dispatch({
        type: ORDER_TYPES.GET_ORDER,
        payload: res.data.data,
      });

      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
