import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import { getOrder } from "../../redux/actions/orderAction";
import { BASE_URL } from "../../utils/fetchData";

const OrderDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  useEffect(() => {
    const getData = () => dispatch(getOrder(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);
  return (
    <>
      {Object.keys(order).length === 0 && <Loading />}
      {Object.keys(order).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            {/* <!--begin::Dashboard--> */}
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">Order No. {order.orderId}</div>
                </div>
              </div>
              <div className="card-body">
                <div className="card card-custom card-border productCard rounded-0 gutter-b">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-120 bg-light">
                              <img
                                src={
                                  order.Stock.Product.image
                                    ? `${BASE_URL}/${order.Stock.Product.image}`
                                    : "assets/img/med-1.png"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="color-secondary">
                              Product Id: {order.Stock.Product.productId}
                            </div>
                          </div>
                          <div>
                            {/* <div className="color-secondary">
                              Exp - July 21, 2022
                            </div> */}
                            <div className="productName">
                              {order.Stock.Product.name}
                            </div>
                            <div className="productBrand">
                              {order.Stock.Product.brand}
                            </div>
                            <div className="color-success">
                              Units Ordered: {order.units}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between flex-column">
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="text-center mr-3">
                              <div className="symbol symbol-80 bg-light">
                                <img
                                  src={
                                    order.Supplier.profilePic
                                      ? `${BASE_URL}/${order.Supplier.profilePic}`
                                      : "assets/media/users/default.jpg"
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div>
                              {/* <div className="color-secondary">
                                Exp - July 21, 2022
                              </div> */}
                              <div className="productName">
                                {order.Supplier.name}
                              </div>
                              <div className="productBrand">
                                {order.Supplier.address}
                              </div>
                              <div className="color-warning">
                                Contract No:{" "}
                                {order.Stock.Product.contractNumber}
                              </div>
                            </div>
                          </div>
                        </div>
                        {order.Distributor && (
                          <div className="d-flex justify-content-between flex-column">
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="text-center mr-3">
                                <div className="symbol symbol-80 bg-light">
                                  <img
                                    src={
                                      order.Distributor.profilePic
                                        ? `${BASE_URL}/${order.Distributor.profilePic}`
                                        : "/assets/media/users/default.jpg"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="productName">
                                  {order.Distributor.name}
                                </div>
                                <div className="productBrand">
                                  {order.Distributor.address}
                                </div>
                              </div>
                              <div className="productBrand">
                                Location:{" "}
                                {`${order.Location.line1}, ${order.Location.line2}, ${order.Location.postalCode}`}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default OrderDetail;
