import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getInvoice } from "../../redux/actions/invoiceAction";
import { BASE_URL } from "../../utils/fetchData";

const Invoice = () => {
  const { state } = useLocation();
  console.log(state);
  const dispatch = useDispatch();
  const { invoice } = useSelector((state) => state.invoice);

  useEffect(() => {
    dispatch(getInvoice(state));
  }, [dispatch, state]);

  if (Object.keys(invoice).length === 0) return;

  const amountBeforeTax = invoice.finalAmount.toFixed(2);
  const tax = (0.13 * parseFloat(invoice.finalAmount)).toFixed(2);
  const amountAfterTax = (
    parseFloat(amountBeforeTax) + parseFloat(tax)
  ).toFixed(2);

  return (
    <div className="healthCareWrapper">
      <div className="healthProInvoiceWrapper">
        {/* <!-- Invoice Header Detail --> */}
        <div className="row headerRow">
          <div className="col-md-6">
            <img
              className="logo"
              src="./assets/img/logo-new.png"
              alt="Health Pro Invoice"
            />
          </div>
          <div className="col-md-6">
            <div className="float-end" style={{ textAlign: "right" }}>
              <p>
                <img
                  style={{ maxHeight: "120px", maxWidth: "120px" }}
                  src={`${BASE_URL}/${invoice.Supplier.profilePic}`}
                  alt="supplier"
                />

                <strong>Supplier</strong>
                <br />
                {invoice.Supplier.name}
                <br />
                {invoice.Supplier.email}
                <br />
                {invoice.Supplier.phone}
                <br />
                {invoice.Supplier.address}
              </p>
            </div>
          </div>
        </div>
        {/* <!-- Invoice Heading --> */}
        <div className="invoiceHeading">Invoice</div>
        {/* <!-- Invoice Info --> */}
        <div className="row">
          <div className="col-md-6">
            <div className="billTo">
              <h3>Bill To</h3>
              <div className="memberShipping">
                <div className="member">
                  <p>
                    <strong>Member</strong> <span>{invoice.Member.name}</span>
                    <span>Email: {invoice.Member.email}</span>
                  </p>
                </div>
                <div className="shipping">
                  <p>
                    <strong>Shipping Address</strong>
                    <span>{invoice.Member.address}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="invoiceInfo">
              <p>
                <strong>Invoice No:</strong>
                <strong>Invoice Date:</strong>
              </p>
              <p>
                <span>{invoice.invoiceId}</span>
                <span>{moment(invoice.createdAt).format("MMM DD, YYYY")}</span>
              </p>
            </div>
          </div>
        </div>
        {/* <!-- Invoice Grid --> */}
        <div className="productTable">
          <div className="table-responsive-md">
            <table className="table table-striped table-binvoiceed">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{invoice.Product.name}</td>
                  <td>{invoice.units}</td>
                  <td>${invoice.Product.price}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="amountDetail">
            <div className="amountColumn">
              <p>
                <strong>SUBTOTAL</strong>
                <span>${amountBeforeTax}</span>
              </p>
              {/* <p>
                <strong>DISCOUNT</strong>
                <span>50.00</span>
              </p> */}
              {/* <p>
                <strong>SUBTOTAL LESS DISCOUNT</strong>
                <span>2523.00</span>
              </p> */}
              <p>
                <strong>TAX RATE</strong>
                <span>13%</span>
              </p>
              <p>
                <strong>TOTAL TAX</strong>
                <span>${tax}</span>
              </p>
              <p className="balanceDue">
                <strong>Balance Due</strong>
                <span>${amountAfterTax}</span>
              </p>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <footer>
          <p>
            <strong>Contact</strong>
            <br />
            360 Oakville Pl Dr, Oakville, ON L6H 6K8
            <br />
            (905) 568-3478
            <br /> <br />
            <span style={{ fontSize: "12px" }}>
              Invoice generated on Reserve Drug Supply Portal HealthPRO Canada.
              All Rights Reserved.{" "}
            </span>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Invoice;
