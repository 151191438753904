import React from "react";
import TablePagination from "@mui/material/TablePagination";

const Pagination = ({ count, page, rowsPerPage, setPage, setRowsPerPage }) => {
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
