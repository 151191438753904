import { LOCATION_TYPES } from "../actions/locationAction";

const initialState = {
  total: 0,
  locations: [],
  locationList: [],
  supplierLocationList: [],
  location: {},
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_TYPES.GET_LOCATIONS:
      return {
        ...state,
        total: action.payload.total,
        locations: action.payload.locations,
      };

    case LOCATION_TYPES.LIST_LOCATIONS:
      return {
        ...state,
        locationList: action.payload.locations,
      };

    case LOCATION_TYPES.LIST_SUPPLIER_LOCATIONS:
      return {
        ...state,
        supplierLocationList: action.payload.locations,
      };

    case LOCATION_TYPES.GET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case LOCATION_TYPES.CREATE_LOCATION:
      return {
        ...state,
        total: state.total + 1,
        locations: [...state.locations, action.payload],
      };

    case LOCATION_TYPES.EDIT_LOCATION:
      return {
        ...state,
        locations: state.locations.map((location) => {
          if (location._id === action.payload._id) return action.payload;
          return location;
        }),
      };

    case LOCATION_TYPES.DELETE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          (location) => location._id !== action.payload
        ),
      };

    case LOCATION_TYPES.APPROVE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case LOCATION_TYPES.DENY_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
};

export default locationReducer;
