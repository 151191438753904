import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import memberReducer from "./memberReducer";
import productReducer from "./productReducer";
import notifyReducer from "./notifyReducer";
import sureSupplyReducer from "./sureSupplyReducer";
import stockReducer from "./stockReducer";
import locationReducer from "./locationReducer";
import supplychainReducer from "./supplychainReducer";
import supplierProductReducer from "./supplierProduct";
import stockSupplierReducer from "./stockSupplierReducer";
import stockReplenishReducer from "./stockReplenishReducer";
import orderReducer from "./orderReducer";
import dashboardReducer from "./dashboardReducer";
import supplierReducer from "./supplierReducer";
import distributorReducer from "./distributorReducer";
import queryReducer from "./queryReducer";
import invoiceReducer from "./invoiceReducer";
import TYPES from "../constants";

const appReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  member: memberReducer,
  supplier: supplierReducer,
  distributor: distributorReducer,
  product: productReducer,
  stock: stockReducer,
  location: locationReducer,
  notify: notifyReducer,
  sureSupply: sureSupplyReducer,
  supplychain: supplychainReducer,
  supplierProduct: supplierProductReducer,
  stockSupplier: stockSupplierReducer,
  stockReplenish: stockReplenishReducer,
  order: orderReducer,
  query: queryReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
});

const rootReducer = (state, action) => {
  //clear state on logout
  if (action.type === TYPES.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
