import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import Loading from "../../components/alert/Loading";
import {
  approveStockRequest,
  denyStockRequest,
  getStockRequest,
} from "../../redux/actions/stockAction";
import { BASE_URL } from "../../utils/fetchData";

const ParLevelDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stockRequest } = useSelector((state) => state.stock);

  useEffect(() => {
    const getData = () => dispatch(getStockRequest(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);

  const handleDenyClick = (e) => {
    dispatch(denyStockRequest(id));
  };

  const handleApproveClick = (e) => {
    dispatch(approveStockRequest(id)).then(() => navigate("/parlevel"));
  };

  return (
    <>
      {Object.keys(stockRequest).length === 0 && <Loading />}
      {Object.keys(stockRequest).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            {/* <!--begin::Dashboard--> */}
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">{stockRequest.Member.name}</div>
                </div>
              </div>
              <div className="card-body">
                <div className="card card-custom card-border productCard rounded-0 gutter-b">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-120 bg-light">
                              <img
                                src={
                                  stockRequest.Stock.Product.image
                                    ? `${BASE_URL}/${stockRequest.Stock.Product.image}`
                                    : "assets/img/med-1.png"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="color-secondary">
                              Product Id: {stockRequest.Stock.Product.productId}
                            </div>
                          </div>
                          <div>
                            {/* <div className="color-secondary">
                          Exp - July 21, 2022
                        </div> */}
                            <div className="productName">
                              {stockRequest.Stock.Product.name}
                            </div>
                            <div className="productBrand">
                              {stockRequest.Stock.Product.brand}
                            </div>
                            <div className="color-success">
                              Par Level: {stockRequest.Stock.parLevel}
                            </div>
                            <div className="color-success">
                              Requested Par Level:{" "}
                              {stockRequest.requestedParLevel}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between flex-column">
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="text-center mr-3">
                              <div className="symbol symbol-80 bg-light">
                                <img
                                  src={
                                    stockRequest.Member.profilePic
                                      ? `${BASE_URL}/${stockRequest.Member.profilePic}`
                                      : "assets/media/users/default.jpg"
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="productName">
                                {stockRequest.Member.name}
                              </div>
                              <div className="productBrand">
                                {stockRequest.Member.email}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <div className="color-primary">
                                <b>Minimum Expiry:</b>{" "}
                                <span>{stockRequest.minimumExpiry} Months</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {stockRequest.status === "PENDING" && (
                  <div>
                    <button
                      className="btn btn-danger"
                      onClick={handleDenyClick}
                    >
                      Deny
                    </button>{" "}
                    <button
                      className="btn btn-blue"
                      onClick={handleApproveClick}
                    >
                      Approve
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default ParLevelDetail;
