import React, { useState } from "react";
import { BASE_URL } from "../../utils/fetchData";
import { useDispatch } from "react-redux";
import Card from "./Card";
import AddManufacturingModal from "./modals/AddManufacturingModal";
import AddMaterialModal from "./modals/AddMaterialModal";
import AddWarehouseModal from "./modals/AddWarehouseModal";
import { submitSupplyChain } from "../../redux/actions/supplychainAction";

const SingleSupplyChain = ({ product }) => {
  const dispatch = useDispatch();

  const {
    _id,
    name,
    brand,
    productId,
    image,
    isApproved,
    materialSuppliers,
    manufacturingFacilities,
    warehouses,
    supplyChain,
  } = product;

  const [showMaterialSupplierModal, setShowMaterialSupplierModal] =
    useState(false);
  const [showManufacturingModal, setShowManufacturingModal] = useState(false);
  const [showWarehousesModal, setShowWarehousesModal] = useState(false);

  const handleSubmit = () => {
    dispatch(submitSupplyChain(supplyChain));
  };

  return (
    <div className="card card-custom card-border productCard rounded-0 gutter-b">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-center flex-wrap">
              <div className="text-center mr-3">
                <div className="symbol symbol-120 bg-light">
                  <img
                    src={`${BASE_URL}/${image}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="color-secondary">Product Id: {productId}</div>
              </div>
              <div>
                <div className="productName">{name.toUpperCase()}</div>
                <div className="productBrand">{brand}</div>
                <div
                  className={`font-weight-500 ${
                    isApproved ? "color-success" : "color-warning"
                  }`}
                >
                  {isApproved
                    ? "Active"
                    : isApproved === false
                    ? "Pending for Approval"
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-custom bg-light">
              <div className="card-header border-0 min-h-1px mt-3">
                <div className="card-label">
                  <p className="font-weight-600">Material Supplier</p>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="text-center">
                  <button
                    className="btn btn-custom-light text-white"
                    onClick={() => setShowMaterialSupplierModal(true)}
                  >
                    Add Material Supplier
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card card-custom bg-light">
              <div className="card-header border-0 min-h-1px mt-3">
                <div className="card-label">
                  <p className="font-weight-600">Manufacturing Facilities</p>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="text-center">
                  <button
                    className="btn btn-custom-light text-white"
                    onClick={() => setShowManufacturingModal(true)}
                  >
                    Add Manufacturing Facilities
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card card-custom bg-light">
              <div className="card-header border-0 min-h-1px mt-3">
                <div className="card-label">
                  <p className="font-weight-600">
                    Warehouses/ Distribution Centres
                  </p>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="text-center">
                  <button
                    className="btn btn-custom-light text-white"
                    onClick={() => setShowWarehousesModal(true)}
                  >
                    Add Warehouses/ Distribution Centres
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-4">
            {materialSuppliers.map((item) => {
              return (
                <Card
                  key={item._id}
                  item={item}
                  supplyChainId={supplyChain}
                  type="Material Supplier"
                />
              );
            })}
          </div>
          <div className="col-lg-4">
            {manufacturingFacilities.map((item) => {
              return (
                <Card
                  key={item._id}
                  item={item}
                  supplyChainId={supplyChain}
                  type="Manufacturing Facility"
                />
              );
            })}
          </div>
          <div className="col-lg-4">
            {warehouses.map((item) => {
              return (
                <Card
                  key={item._id}
                  item={item}
                  supplyChainId={supplyChain}
                  type="Warehouse"
                />
              );
            })}
          </div>
        </div>
        {isApproved !== false && isApproved !== true && (
          <div className="text-right">
            <button
              onClick={handleSubmit}
              className="btn btn-gradient btn-pill px-8"
            >
              Submit
            </button>
          </div>
        )}
      </div>
      <AddMaterialModal
        productId={_id}
        modalShow={showMaterialSupplierModal}
        handleClose={() => setShowMaterialSupplierModal(false)}
      />
      <AddManufacturingModal
        productId={_id}
        modalShow={showManufacturingModal}
        handleClose={() => setShowManufacturingModal(false)}
      />
      <AddWarehouseModal
        productId={_id}
        modalShow={showWarehousesModal}
        handleClose={() => setShowWarehousesModal(false)}
      />
    </div>
  );
};

export default SingleSupplyChain;
