import React, { useState } from "react";
import AssignedListModal from "./modals/AssignedListModal";
import { BASE_URL } from "../../utils/fetchData";
import ReplaceStockModal from "../parlevel/modals/ReplaceStockModal";

const SingleStockSupplier = ({ stock }) => {
  const {
    _id,
    Product: {
      productId,
      name: productName,
      brand: productBrand,
      image: productImage,
      contractNumber,
    },
    Member: { name: memberName, profilePic, address: memberAddress },
    totalAssignedUnits,
    minimumExpiry,
    StockUnits,
    isReplacable,
  } = stock;

  const [showStockModal, setShowStockModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  return (
    <div className="card card-custom card-border productCard rounded-0 gutter-b">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex align-items-center flex-wrap">
              <div className="text-center mr-3">
                <div className="symbol symbol-120 bg-light">
                  <img
                    src={`${BASE_URL}/${productImage}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="color-secondary">Product Id: {productId}</div>
              </div>
              <div>
                <div className="productName">{productName.toUpperCase()}</div>
                <div className="productBrand">{productBrand}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-between align-items-stretch h-100 flex-column">
              <div className="d-flex align-items-center flex-wrap">
                <div className="text-center mr-3">
                  <div className="symbol symbol-60 bg-light p-3">
                    <img
                      src={
                        profilePic
                          ? `${BASE_URL}/${profilePic}`
                          : "assets/media/users/default.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div>
                  <div className="productName">{memberName}</div>
                  <div className="productBrand">{memberAddress}</div>
                  <div className="color-warning">
                    Contract No: {contractNumber}
                  </div>
                  <div className="color-success">
                    Assigned Stock: {totalAssignedUnits}{" "}
                    <span>
                      <i
                        className="fas fa-info-circle text-dark"
                        onClick={() => setShowStockModal(true)}
                      ></i>
                    </span>
                  </div>
                  <div className="color-success">
                    Minimum Expiry: {minimumExpiry} months
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isReplacable && (
        <div className="card-footer py-2">
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-gradient btn-pill px-8"
              onClick={() => setShowAssignModal(true)}
            >
              Replace Stock
            </button>
          </div>
        </div>
      )}
      {/* <!-- start::Modals--> */}
      <AssignedListModal
        units={StockUnits}
        modalShow={showStockModal}
        handleClose={() => setShowStockModal(false)}
      />
      <ReplaceStockModal
        stockId={_id}
        stockUnits={StockUnits}
        minimumExpiry={minimumExpiry}
        modalShow={showAssignModal}
        handleClose={() => setShowAssignModal(false)}
      />
      {/* <!-- end::Modals--> */}
    </div>
  );
};

export default SingleStockSupplier;
