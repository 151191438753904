import React from "react";

const StockRow = ({ stock, handleChange, handleDelete, locationList }) => {
  return stock.map((item, index) => {
    return (
      <div className="row" key={index}>
        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="">Choose Location</label>
            <select
              id="locations"
              name="locationId"
              className="form-control form-control-solid"
              value={item.locationId}
              onChange={(e) => handleChange(index, e)}
            >
              <option value="">Select...</option>
              {locationList.map((location) => {
                return (
                  <option key={location._id} value={location._id}>
                    {location.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group">
            <label htmlFor="">Enter Units</label>
            <input
              type="number"
              name="assignedUnits"
              value={item.assignedUnits}
              onChange={(e) => handleChange(index, e)}
              className="form-control form-control-solid"
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group">
            <label htmlFor="">Expiry Date</label>
            <input
              type="date"
              name="expiry"
              value={item.expiry}
              onChange={(e) => handleChange(index, e)}
              className="form-control form-control-solid"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group">
            <label htmlFor="">Lot Number</label>
            <input
              type="text"
              name="lotNumber"
              value={item.lotNumber}
              onChange={(e) => handleChange(index, e)}
              className="form-control form-control-solid"
            />
          </div>
        </div>
        <div className="col-lg-1">
          <div className="form-group">
            <i
              className="fas fa-window-close"
              style={{ marginTop: "35px" }}
              onClick={() => handleDelete(index)}
            ></i>
          </div>
        </div>
      </div>
    );
  });
};

export default StockRow;
