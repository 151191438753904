import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../components/common/Profile";
import AssignedStock from "../components/stock/AssignedStock";
import PrivateRoute from "../customRouter/PrivateRoute";
import TransferStockDetail from "../pages/common/TransferStockDetail";
import ProcessedOrderDetail from "../pages/healthpro/ProcessedOrderDetail";
import Layout from "../templates/MemberLayout";
import Invoice from "../components/common/Invoice";
import StockRequest from "../pages/member/StockRequest";
import ParLevelComments from "../pages/member/ParLevelComments";
import Replenishment from "../pages/member/Replenishment";
import StockReplenishComments from "../pages/member/StockReplenishComments";

//pages
const Dashboard = React.lazy(() => import("../pages/member/Dashboard"));
const Login = React.lazy(() => import("../pages/common/Login"));
const Member = React.lazy(() => import("../pages/member/Member"));
const Notification = React.lazy(() => import("../pages/member/Notification"));
const Order = React.lazy(() => import("../pages/member/Order"));
const Product = React.lazy(() => import("../pages/member/Product"));
const Stock = React.lazy(() => import("../pages/member/Stock"));
const SureSupplyShield = React.lazy(() =>
  import("../pages/member/SureSupplyShield")
);
const OTP = React.lazy(() => import("../pages/common/OTP"));
const Contact = React.lazy(() => import("../pages/member/Contact"));

const MemberRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/verify" element={<OTP />} />
        <Route exact path="/invoice" element={<Invoice />} />

        <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/member"
          element={
            <PrivateRoute>
              <Layout>
                <Member />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/order"
          element={
            <PrivateRoute>
              <Layout>
                <Order />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/product"
          element={
            <PrivateRoute>
              <Layout>
                <Product />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stock"
          element={
            <PrivateRoute>
              <Layout>
                <Stock />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stockrequests"
          element={
            <PrivateRoute>
              <Layout>
                <StockRequest />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stockrequests/:id/comments"
          element={
            <PrivateRoute>
              <Layout>
                <ParLevelComments />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stockreplenishments"
          element={
            <PrivateRoute>
              <Layout>
                <Replenishment />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stockreplenishment/:id/comments"
          element={
            <PrivateRoute>
              <Layout>
                <StockReplenishComments />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/notifications"
          element={
            <PrivateRoute>
              <Layout>
                <Notification />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/sure-supply-shield"
          element={
            <PrivateRoute>
              <Layout>
                <SureSupplyShield />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <PrivateRoute>
              <Layout>
                <Contact />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/me"
          element={
            <PrivateRoute>
              <Layout>
                <Profile />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/order/:id/processed"
          element={
            <PrivateRoute>
              <Layout>
                <ProcessedOrderDetail />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stocktransfer/:id"
          element={
            <PrivateRoute>
              <Layout>
                <TransferStockDetail />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/stock/:id"
          element={
            <PrivateRoute>
              <Layout>
                <AssignedStock />
              </Layout>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MemberRoutes;
