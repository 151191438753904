import React from "react";
import { BASE_URL } from "../../utils/fetchData";

const SingleSupplierProduct = ({ product }) => {
  const { productId, name, brand, image, price, contractNumber } = product;
  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img src={`${BASE_URL}/${image}`} alt="" className="img-fluid" />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {name && name.toUpperCase()}
            </div>
            <div className="color-secondary">{brand}</div>
          </div>
        </div>
      </td>
      <td>{productId}</td>
      <td>{contractNumber}</td>
      <td>{price && price.toFixed(2)}</td>
      <td>
        <span className="color-success">Active</span>
      </td>
    </tr>
  );
};

export default SingleSupplierProduct;
