import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="brand flex-column-auto" id="kt_brand">
      {/* <!--begin::Logo--> */}
      <Link to="/" className="brand-logo">
        <img
          style={{ width: "100%" }}
          alt="Logo"
          src="/assets/img/logo-light.jpg"
        />
      </Link>
      {/* <!--end::Logo--> */}
    </div>
  );
};

export default Logo;
