import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/common/Pagination";
import SingleExpiringOrder from "../../components/order/SingleExpiringOrder";
import { getExpiringLotOrders } from "../../redux/actions/orderAction";

const ExpiringLots = () => {
  const dispatch = useDispatch();
  const { ordersExpiring, totalExpiring } = useSelector((state) => state.order);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getExpiringLotOrders("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(
          getExpiringLotOrders(
            searchTerm,
            sort,
            rowsPerPage,
            page * rowsPerPage
          )
        );
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);
  return (
    <div className="d-flex flex-column-fluid">
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Expiring Lots{" "}
                    <small>
                      Total {totalExpiring}{" "}
                      {totalExpiring === 1 ? "Order" : "Orders"}{" "}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div>
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Items</th>
                      <th>Member</th>
                      <th>Lot No.</th>
                      <th>Expiry Date</th>
                      <th>Location</th>
                      <th>Units</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordersExpiring.map((order) => {
                      return (
                        <SingleExpiringOrder key={order._id} order={order} />
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Pagination
                count={totalExpiring}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </div>
          </div>
        </div>
        {/* <!--end::Dashboard--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default ExpiringLots;
