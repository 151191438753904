import React, { useState } from "react";
import Comment from "../../components/common/Comment";
import { useParams } from "react-router";
import { BASE_URL } from "../../utils/fetchData";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addComment,
  approveStocks,
  getStockRequest,
} from "../../redux/actions/stockAction";
import { useEffect } from "react";
import Loading from "../../components/alert/Loading";

const ParLevelComments = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = () => dispatch(getStockRequest(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);

  const { user } = useSelector((state) => state.auth);
  const { stockRequest } = useSelector((state) => state.stock);
  let { StockRequestUnits, StockRequestComments, Member, Supplier, status } =
    stockRequest;

  StockRequestComments &&
    StockRequestComments.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

  const [comment, setComment] = useState("");

  const handleAddComment = (e) => {
    e.preventDefault();

    dispatch(addComment(stockRequest._id, comment));
    setComment("");
  };

  const handleApprove = () => {
    dispatch(approveStocks(id));
  };

  return (
    <>
      {Object.keys(stockRequest).length === 0 && <Loading />}
      {Object.keys(stockRequest).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container-fluid">
            <div className="card card-custom">
              <div className="card-header border-0">
                <div
                  className="card-title"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="card-label">Proposed Stock Locations</div>
                  <div className="card-label">
                    {status === "ASSIGNED_REQUEST" ? (
                      <button className="btn btn-blue" onClick={handleApprove}>
                        Approve
                      </button>
                    ) : status === "ASSIGN_APPROVED" ? (
                      <label style={{ color: "green" }}>
                        Waiting for Stock Assignment
                      </label>
                    ) : status === "ASSIGNED" ? (
                      <label style={{ color: "green" }}>Assigned</label>
                    ) : (
                      <label style={{ color: "red" }}>Not Approved</label>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Location</th>
                      <th>Units</th>
                      <th>Lot Number</th>
                      <th>Expiry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {StockRequestUnits.length > 0 &&
                      StockRequestUnits.map((unit, index) => {
                        const {
                          lotNumber,
                          expiry,
                          assignedUnits,
                          Location: { name, line1, line2, postalCode },
                        } = unit;

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${name}, ${line1}, ${line2}, ${postalCode}`}</td>
                            <td>{assignedUnits}</td>
                            <td>{lotNumber}</td>
                            <td>
                              {expiry && moment(expiry).format("DD MMM, YYYY")}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!--begin::Dashboard-->
              <!--begin::Row--> */}
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-custom border-radius-18px">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <div className="card-label">
                        Comments
                        <small>
                          Total {StockRequestComments.length}{" "}
                          {StockRequestComments.length === 1
                            ? "Comment"
                            : "Comments"}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="comment-section">
                      {StockRequestComments.map((comment, index) => (
                        <Comment
                          key={index}
                          comment={comment}
                          member={Member}
                          supplier={Supplier}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="card-header border-0">
                    <div className="card-title">
                      <div className="card-label">Add New Comments</div>
                    </div>
                  </div>
                  <div class="comment-section">
                    <div class="comment">
                      <div class="user-avatar">
                        <img
                          src={
                            user.profilePic
                              ? `${BASE_URL}/${user.profilePic}`
                              : "assets/media/users/default.jpg"
                          }
                          alt="User Avatar"
                        />
                      </div>
                      <div class="comment-content">
                        <p class="username">{user.name}</p>
                        <div class="comment-text">
                          <form
                            className="reply-form"
                            onSubmit={handleAddComment}
                          >
                            <textarea
                              placeholder="Write a comment..."
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                            <button type="submit">Submit Comment</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--end::Dashboard--> */}
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default ParLevelComments;
