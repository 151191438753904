import { getDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const DASHBOARD_TYPES = {
  GET_DATA: "GET_DATA",
};

export const getDashboardData = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/dashboard`, token);

    if (res.data.success) {
      dispatch({
        type: DASHBOARD_TYPES.GET_DATA,
        payload: {
          orders: res.data.data.orders,
          totalOrders: res.data.data.totalOrders,
          totalMembers: res.data.data.totalMembers,
          totalProducts: res.data.data.totalProducts,
          totalSureSupply: res.data.data.totalSureSupply,
          totalSuppliers: res.data.data.totalSuppliers,
          totalDistributors: res.data.data.totalDistributors,
          totalLocations: res.data.data.totalLocations,
        },
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
