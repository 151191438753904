import React, { useState } from "react";
import ReplenishStockModal from "./modals/ReplenishStockModal";
import { BASE_URL } from "../../utils/fetchData";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { assignStocksPermanent } from "../../redux/actions/stockReplenishAction";

const SingleReplenish = ({ request }) => {
  const {
    _id,
    units,
    Stock: {
      minimumExpiry,
      Product: { name: productName, brand },
    },
    Member: { name, email, address, profilePic },
    status,
    distributorId,
    invoiceId,
  } = request;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const statusTabColor =
    status === "PENDING"
      ? "primary"
      : status === "ASSIGNED_REQUEST" || status === "APPROVED"
      ? "warning"
      : status === "DENIED"
      ? "danger"
      : "dark";

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center flex-row">
          {/* <div className="text-center mr-3">
            <div className="symbol symbol-50 bg-light">
              <img
                src={
                  productImage
                    ? `${BASE_URL}/${productImage}`
                    : "assets/img/med-1.png"
                }
                alt=""
                className="img-fluid"
              />
            </div>
          </div> */}
          <div>
            <div className="font-size-lg font-weight-boldest color-primary">
              {productName}
            </div>
            <div className="font-size-sm font-weight-bold color-primary">
              {brand}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div
          className="d-flex align-items-start"
          style={{ flexDirection: "column" }}
        >
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                profilePic
                  ? `${BASE_URL}/${profilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {name}
            </div>
            <div className="color-secondary">{email}</div>
          </div>
        </div>
      </td>

      <td>{address}</td>
      <td>{units}</td>
      <td>{minimumExpiry && `${minimumExpiry} months`}</td>
      <td>
        <span className={`badge rounded-pill badge-${statusTabColor}`}>
          {status}
        </span>
      </td>
      <td>
        <button
          className={`btn btn-blue ${
            (status === "COMPLETED" ||
              status === "APPROVED" ||
              status === "DENIED") &&
            "disabled"
          }`}
          disabled={
            status === "COMPLETED" ||
            status === "APPROVED" ||
            status === "DENIED"
              ? true
              : false
          }
          onClick={() => setShowModal(true)}
        >
          Replenish
        </button>
      </td>
      <td>
        {status !== "PENDING" && (
          <button
            className="btn btn-success"
            onClick={() => navigate(`/stockreplenishment/${_id}/comments`)}
          >
            Comments
          </button>
        )}
      </td>
      <td>
        {status !== "PENDING" && (
          <button
            className="btn btn-blue"
            onClick={() => navigate("/invoice", { state: invoiceId })}
          >
            Invoice
          </button>
        )}
      </td>
      <td>
        {status === "COMPLETED" ? (
          <label style={{ color: "green" }}>Paid</label>
        ) : status === "APPROVED" ? (
          <button
            className="btn btn-blue"
            onClick={() => dispatch(assignStocksPermanent(_id))}
          >
            Mark
          </button>
        ) : (
          <label style={{ color: "red" }}>Not Paid</label>
        )}
      </td>
      <ReplenishStockModal
        requestId={_id}
        isDistributor={distributorId ? true : false}
        minimumExpiry={minimumExpiry}
        modalShow={showModal}
        handleClose={() => setShowModal(false)}
      />
    </tr>
  );
};

export default SingleReplenish;
