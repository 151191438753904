import React from "react";
import HeaderMenu from "./HeaderMenu";
import Topbar from "./Topbar";

const Header = ({ menu }) => {
  return (
    <div id="kt_header" className="header header-fixed revampedHeader">
      {/* <!--begin::Container--> */}
      <div className="container-fluid d-flex align-items-center justify-content-between">
        {/* <!--begin::Header Menu Wrapper--> */}
        <div
          className="header-menu-wrapper header-menu-wrapper-left"
          id="kt_header_menu_wrapper"
        >
          {/* <!--begin::Header Menu--> */}
          {menu && <HeaderMenu />}
          {/* <!--end::Header Menu--> */}
        </div>
        {/* <!--end::Header Menu Wrapper-->
							<!--begin::Topbar--> */}
        <Topbar />
        {/* <!--end::Topbar--> */}
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Header;
