import { getDataAPI, patchDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const NOTIFY_TYPES = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SEEN_NOTIFICATION: "SEEN_NOTIFICATION",
  GET_COUNT: "GET_COUNT",
};

export const getNotifications =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/notifications?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: NOTIFY_TYPES.GET_NOTIFICATIONS,
          payload: {
            notifications: res.data.data.notifications,
            total: res.data.data.total,
            countUnseen: res.data.data.unreadCount,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const countUnseenNotifications = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/notifications/unseen`, token);

    if (res.data.success) {
      dispatch({
        type: NOTIFY_TYPES.GET_COUNT,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const seenNotification =
  (notificationId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/notification/${notificationId}/seen`,
        {},
        token
      );

      if (res.data.success) {
        const resp = await getDataAPI(`${usertype}/notifications`, token);

        if (resp.data.success) {
          dispatch({
            type: NOTIFY_TYPES.SEEN_NOTIFICATION,
            payload: notificationId,
          });
          dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        }
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };
