const getNotifyDetailView = (notification, recipientType) => {
  const {
    type,
    stockId,
    stockRequestId,
    stockReplenishId,
    orderId,
    locationId,
    supplyChainId,
    stockTransferRequestId,
  } = notification;

  if (type === "STOCK_REQUEST_GENERATED" && recipientType === "healthpro")
    return `/parlevel/${stockRequestId}`;
  else if (type === "STOCK_REQUEST_GENERATED" && recipientType === "supplier")
    return `/parlevel/${stockRequestId}`;
  else if (type === "STOCK_REQUEST_APPROVED" && recipientType === "healthpro")
    return `/notifications`;
  else if (type === "STOCK_REQUEST_DENIED" && recipientType === "healthpro")
    return `/notifications`;
  else if (type === "STOCK_REQUEST_APPROVED" && recipientType === "member")
    return `/stock`;
  else if (type === "STOCK_REQUEST_DENIED" && recipientType === "member")
    return `/stock`;
  else if (type === "ORDER_CREATED" && recipientType === "healthpro")
    return `/order/${orderId}/new`;
  else if (type === "ORDER_CREATED" && recipientType === "supplier")
    return `/order/${orderId}/new`;
  else if (type === "ORDER_CREATED" && recipientType === "distributor")
    return `/order/${orderId}/new`;
  else if (type === "ORDER_DISPATCHED" && recipientType === "healthpro")
    return `/order/${orderId}/processed`;
  else if (type === "ORDER_DISPATCHED" && recipientType === "member")
    return `/order/${orderId}/processed`;
  else if (type === "ORDER_COMPLETED" && recipientType === "healthpro")
    return `/order/${orderId}/completed`;
  else if (type === "ORDER_COMPLETED" && recipientType === "supplier")
    return `/order/${orderId}/completed`;
  else if (type === "ORDER_COMPLETED" && recipientType === "distributor")
    return `/order/${orderId}/completed`;
  else if (type === "ORDER_APPROVED" && recipientType === "supplier")
    return `/expiringlots`;
  else if (type === "ORDER_APPROVED" && recipientType === "distributor")
    return `/expiringlots`;
  else if (type === "STOCK_TRANSFER_REQUEST" && recipientType === "member")
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_APPROVED" &&
    recipientType === "member"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_DENIED" &&
    recipientType === "member"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (type === "STOCK_TRANSFER_REQUEST" && recipientType === "supplier")
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_APPROVED" &&
    recipientType === "supplier"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_DENIED" &&
    recipientType === "supplier"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (type === "STOCK_TRANSFER_REQUEST" && recipientType === "distributor")
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_APPROVED" &&
    recipientType === "distributor"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_DENIED" &&
    recipientType === "distributor"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (type === "STOCK_TRANSFER_REQUEST" && recipientType === "healthpro")
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_APPROVED" &&
    recipientType === "healthpro"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (
    type === "STOCK_TRANSFER_REQUEST_DENIED" &&
    recipientType === "healthpro"
  )
    return `/stocktransfer/${stockTransferRequestId}`;
  else if (type === "LOCATION_CREATED") return `/location/${locationId}`;
  else if (type === "LOCATION_APPROVED") return `/location`;
  else if (type === "LOCATION_REJECTED") return `'/location`;
  else if (
    type === "SUPPLY_CHAIN_INFO_CREATED" &&
    recipientType === "healthpro"
  )
    return `/supplychain/${supplyChainId}`;
  else if (
    type === "SUPPLY_CHAIN_INFO_APPROVED" &&
    recipientType === "supplier"
  )
    return `/supplychain/${supplyChainId}`;
  else if (type === "SUPPLY_CHAIN_INFO_DENIED" && recipientType === "supplier")
    return `/supplychain/${supplyChainId}`;
  else if (
    type === "SUPPLY_CHAIN_FLAG_CHANGED" &&
    recipientType === "healthpro"
  )
    return `/supplychain/${supplyChainId}`;
  else if (type === "STOCK_ASSIGNED" && recipientType === "member")
    return `/stock/${stockId}`;
  else if (type === "STOCK_ASSIGNED" && recipientType === "healthpro")
    return `/stock/${stockId}`;
  else if (type === "STOCK_ASSIGNED" && recipientType === "distributor")
    return `/stock/${stockId}`;
  else if (type === "STOCK_REPLENISHED" && recipientType === "member")
    return `/stock/${stockId}`;
  else if (type === "STOCK_REPLENISHED" && recipientType === "healthpro")
    return `/stock/${stockId}`;
  else if (type === "STOCK_REPLENISHED" && recipientType === "supplier")
    return `/stock/${stockId}`;
  else if (type === "STOCK_REPLENISHED" && recipientType === "distributor")
    return `/stock/${stockId}`;
  else if (type === "STOCK_ASSIGNED_REQUEST" && recipientType === "member")
    return `/stockrequests/${stockRequestId}/comments`;
  else if (
    type === "ADD_COMMENT" &&
    stockRequestId &&
    recipientType === "supplier"
  )
    return `/stockrequests/${stockRequestId}/comments`;
  else if (
    type === "ADD_COMMENT" &&
    stockRequestId &&
    recipientType === "member"
  )
    return `/stockrequests/${stockRequestId}/comments`;
  else if (
    type === "PROPOSED_STOCK_APPROVED" &&
    stockRequestId &&
    recipientType === "supplier"
  )
    return `/stockrequests/${stockRequestId}/comments`;
  else if (
    type === "ADD_COMMENT" &&
    stockReplenishId &&
    recipientType === "supplier"
  )
    return `/stockreplenisment/${stockReplenishId}/comments`;
  else if (
    type === "ADD_COMMENT" &&
    stockReplenishId &&
    recipientType === "member"
  )
    return `/stockreplenishment/${stockReplenishId}/comments`;
};

export default getNotifyDetailView;
