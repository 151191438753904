import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "../components/common/Profile";
import AssignedStock from "../components/stock/AssignedStock";
import PrivateRoute from "../customRouter/PrivateRoute";
import TransferStockDetail from "../pages/common/TransferStockDetail";
import OrderDetail from "../pages/healthpro/OrderDetail";
import ProcessedOrderDetail from "../pages/healthpro/ProcessedOrderDetail";
import SupplyChainDetail from "../pages/healthpro/SupplyChainDetail";
import AssignedSupplierStock from "../pages/supplier/AssignedSupplierStock";
import Contact from "../pages/supplier/Contact";
import ExpiringLots from "../pages/supplier/ExpiringLots";
import Location from "../pages/supplier/Location";
import Notification from "../pages/supplier/Notification";
import Order from "../pages/supplier/Order";
import ParLevel from "../pages/supplier/ParLevel";
import ParLevelDetail from "../pages/supplier/ParLevelDetail";
import Product from "../pages/supplier/Product";
import Replenishment from "../pages/supplier/Replenishment";
import SupplyChain from "../pages/supplier/SupplyChain";
import Layout from "../templates/SupplierLayout";
import Invoice from "../components/common/Invoice";
import ParLevelComments from "../pages/supplier/ParLevelComments";
import StockReplenishComments from "../pages/supplier/StockReplenishComments";
import AggregatedStock from "../pages/supplier/AggregatedStock";

//pages
const Dashboard = React.lazy(() => import("../pages/supplier/Dashboard"));
const Login = React.lazy(() => import("../pages/common/Login"));
const OTP = React.lazy(() => import("../pages/common/OTP"));

const SupplierRoutes = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/verify" element={<OTP />} />
      <Route exact path="/invoice" element={<Invoice />} />
      <Route
        exact
        path="/"
        element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/parlevel"
        element={
          <PrivateRoute>
            <Layout>
              <ParLevel />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stockrequests/:id/comments"
        element={
          <PrivateRoute>
            <Layout>
              <ParLevelComments />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stockreplenishment/:id/comments"
        element={
          <PrivateRoute>
            <Layout>
              <StockReplenishComments />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order"
        element={
          <PrivateRoute>
            <Layout>
              <Order />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/notifications"
        element={
          <PrivateRoute>
            <Layout>
              <Notification />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/replenishment"
        element={
          <PrivateRoute>
            <Layout>
              <Replenishment />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/members/stock"
        element={
          <PrivateRoute>
            <Layout>
              <AssignedSupplierStock />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stock"
        element={
          <PrivateRoute>
            <Layout>
              <AggregatedStock />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/location"
        element={
          <PrivateRoute>
            <Layout>
              <Location />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/product"
        element={
          <PrivateRoute>
            <Layout>
              <Product />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/supplychain"
        element={
          <PrivateRoute>
            <Layout>
              <SupplyChain />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/expiringlots"
        element={
          <PrivateRoute>
            <Layout>
              <ExpiringLots />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/contact"
        element={
          <PrivateRoute>
            <Layout>
              <Contact />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/me"
        element={
          <PrivateRoute>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/parlevel/:id"
        element={
          <PrivateRoute>
            <Layout>
              <ParLevelDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stock/:id"
        element={
          <PrivateRoute>
            <Layout>
              <AssignedStock />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/new"
        element={
          <PrivateRoute>
            <Layout>
              <OrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/order/:id/completed"
        element={
          <PrivateRoute>
            <Layout>
              <ProcessedOrderDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/stocktransfer/:id"
        element={
          <PrivateRoute>
            <Layout>
              <TransferStockDetail />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/supplychain/:id"
        element={
          <PrivateRoute>
            <Layout>
              <SupplyChainDetail />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default SupplierRoutes;
