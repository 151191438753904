import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import { getSingleStock } from "../../redux/actions/stockAction";
import { BASE_URL } from "../../utils/fetchData";

const AssignedStock = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { stock } = useSelector((state) => state.stock);
  console.log(stock);

  useEffect(() => {
    const getData = () => dispatch(getSingleStock(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);
  return (
    <>
      {Object.keys(stock).length === 0 && <Loading />}
      {Object.keys(stock).length > 0 && (
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-custom border-radius-18px">
                  <div className="card-header border-0">
                    <div className="card-title">
                      <div className="card-label">{stock.Product.name}</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="card card-custom card-stretch card-border productCard rounded-0 gutter-b">
                          <div className="card-body p-3">
                            <h5 className="font-weight-boldest color-primary mb-10">
                              Member Details
                            </h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="text-center mr-3">
                                <div className="symbol symbol-80 bg-light">
                                  <img
                                    src={
                                      stock.Member.profilePic
                                        ? `${BASE_URL}/${stock.Member.profilePic}`
                                        : "/assets/img/supplier-1.png"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="productName">
                                  {stock.Member.name}
                                </div>
                                <div className="productBrand">
                                  {stock.Member.address}
                                </div>
                                <div className="productBrand">
                                  {stock.Member.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="card card-custom card-border card-stretch productCard rounded-0 gutter-b">
                          <div className="card-body p-3">
                            <h5 className="font-weight-boldest color-primary mb-10">
                              Product Details
                            </h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="text-center mr-3">
                                <div className="symbol symbol-120 bg-light">
                                  <img
                                    src={
                                      stock.Product.image
                                        ? `${BASE_URL}/${stock.Product.image}`
                                        : "/assets/media/users/default.jpg"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="color-secondary">
                                  Product Id: {stock.Product.productId}
                                </div>
                              </div>
                              <div>
                                <div className="productName">
                                  {stock.Product.name}
                                </div>
                                <div className="productBrand">
                                  {stock.Product.brand}
                                </div>
                                <div className="color-success">
                                  Assigned Stock: {stock.totalAssignedUnits}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card card-custom card-border card-stretch productCard rounded-0 gutter-b">
                          <div className="card-body p-3">
                            <h5 className="font-weight-boldest color-primary mb-10">
                              Supplier Details
                            </h5>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="text-center mr-3">
                                <div className="symbol symbol-120 bg-light">
                                  <img
                                    src={
                                      stock.Product.Supplier.profilePic
                                        ? `${BASE_URL}/${stock.Product.Supplier.profilePic}`
                                        : "/assets/img/supplier-1.png"
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="productName">
                                  {stock.Product.Supplier.name}
                                </div>
                                <div className="productBrand">
                                  {stock.Product.Supplier.address}
                                </div>
                                <div className="productBrand">
                                  {stock.Product.Supplier.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-5">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Location</th>
                              <th>Units</th>
                              <th>Lot Number</th>
                              <th>Expiry</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stock.StockUnits.map((stockUnit, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{`${stockUnit.Location.line1}, ${stockUnit.Location.line2}, ${stockUnit.Location.postalCode}`}</td>
                                  <td>{stockUnit.assignedUnits}</td>
                                  <td>{stockUnit.lotNumber}</td>
                                  <td>
                                    {stockUnit.expiry &&
                                      moment(stockUnit.expiry).format(
                                        "MMM DD, YYYY"
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignedStock;
