import { STOCK_REPLENISH_TYPES } from "../actions/stockReplenishAction";

const initialState = {
  requests: [],
  total: 0,
  replenishRequest: {},
};

const stockReplenishReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_REPLENISH_TYPES.GET_REPLENISH:
      return {
        ...state,
        total: action.payload.total,
        requests: action.payload.stockReplenish,
      };

    case STOCK_REPLENISH_TYPES.REMOVE_REPLENISHED_STOCK:
      return {
        ...state,
        total: state.total - 1,
        requests: state.requests.filter(
          (request) => request._id !== action.payload
        ),
      };

    case STOCK_REPLENISH_TYPES.GET_REPLENISH_REQUEST:
      return {
        ...state,
        replenishRequest: action.payload,
      };

    case STOCK_REPLENISH_TYPES.POST_COMMENT:
      return {
        ...state,
        replenishRequest: {
          ...state.replenishRequest,
          StockRequestComments: [
            action.payload,
            ...state.replenishRequest.StockRequestComments,
          ],
        },
      };

    case STOCK_REPLENISH_TYPES.UPDATE_STATUS:
      return {
        ...state,
        replenishRequest: {
          ...state.replenishRequest,
          status: action.payload,
        },
      };

    case STOCK_REPLENISH_TYPES.UPDATE_STOCK_STATUS:
      return {
        ...state,
        requests: state.requests.map((stock) => {
          if (stock._id === action.payload.stockReplenishId)
            return { ...stock, status: action.payload.status };
          return { ...stock };
        }),
      };

    case STOCK_REPLENISH_TYPES.UPDATE_REPLENISH_REQUEST:
      return {
        ...state,
        requests: state.requests.map((stock) => {
          if (stock._id === action.payload._id) return { ...action.payload };
          return { ...stock };
        }),
      };
    default:
      return state;
  }
};

export default stockReplenishReducer;
