import { getDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const PRODUCT_TYPES = {
  GET_PRODUCTS: "GET_PRODUCTS",
};

export const getProducts =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/products?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: PRODUCT_TYPES.GET_PRODUCTS,
          payload: {
            products: res.data.data.products,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };
