import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, editProduct } from "../../redux/actions/productAction";
import { listSuppliers } from "../../redux/actions/supplierAction";
import Select from "react-select";

const CreateProduct = ({ product, setEditProduct, modalShow, handleClose }) => {
  const dispatch = useDispatch();
  const { supplierList } = useSelector((state) => state.supplier);

  const [id, setId] = useState(product._id);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(product.name || "");
  const [brand, setBrand] = useState(product.brand || "");
  const [productId, setProductId] = useState(product.productId || "");
  const [price, setPrice] = useState(product.price || 0);
  const [minimumOrderSize, setMinimumOrderSize] = useState(
    product.minimumOrderSize || "500"
  );
  const [contractNumber, setContractNumber] = useState(
    product.contractNumber || ""
  );
  const [supplierId, setSupplierId] = useState(
    {
      value: product.Supplier && product.Supplier._id,
      label: product.Supplier && product.Supplier.name,
    } || {}
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(product).length > 0) {
      const payload = {
        id,
        image,
        name,
        brand,
        productId,
        contractNumber,
        price,
        minimumOrderSize,
        supplierId: supplierId.value,
      };

      dispatch(editProduct(payload)).then(() => {
        //reset state
        setId(null);
        setImage(null);
        setName("");
        setBrand("");
        setContractNumber("");
        setProductId("");
        setPrice(0);
        setMinimumOrderSize("500");
        setSupplierId("");
      });

      //reset html form
      e.target.reset();

      let dragA = document.querySelector("#kt_dropzone_1");
      const initialDragArea = `<div class="dropzone-msg dz-message needsclick">
            <h3 class="dropzone-msg-title">
              Drop file here or click to upload.
            </h3>
            <input
              type="file"
              class="upload-button"
              name="image"
              hidden
            />
          </div>`;
      dragA.innerHTML = initialDragArea;
      const h3 = document.querySelector(".dropzone-msg-title");
      h3.addEventListener("click", handleClick);
      const input = document.querySelector(".upload-button");
      input.addEventListener("change", handleUpload);

      setEditProduct();
    } else {
      const payload = {
        image,
        name,
        brand,
        productId,
        contractNumber,
        price,
        minimumOrderSize,
        supplierId: supplierId.value,
      };

      dispatch(createProduct(payload)).then(() => {
        //reset state
        setImage(null);
        setName("");
        setBrand("");
        setContractNumber("");
        setProductId("");
        setPrice(0);
        setMinimumOrderSize("500");
        setSupplierId("");
      });

      //reset html form
      e.target.reset();

      let dragA = document.querySelector("#kt_dropzone_1");
      const initialDragArea = `<div class="dropzone-msg dz-message needsclick">
            <h3 class="dropzone-msg-title">
              Drop file here or click to upload.
            </h3>
            <input
              type="file"
              class="upload-button"
              name="image"
              hidden
            />
          </div>`;
      dragA.innerHTML = initialDragArea;
      const h3 = document.querySelector(".dropzone-msg-title");
      h3.addEventListener("click", handleClick);
      const input = document.querySelector(".upload-button");
      input.addEventListener("change", handleUpload);
    }
    handleClose();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const dragText = document.querySelector(".dropzone-msg-title");
    dragText.textContent = "Release to upload.";
  };

  const handleDragLeave = () => {
    const dragText = document.querySelector(".dropzone-msg-title");
    dragText.textContent = "Drop file here or click to upload.";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    displayFile(file);
  };

  const displayFile = (file) => {
    const dragArea = document.querySelector("#kt_dropzone_1");
    let fileType = file.type;
    let validExtensions = ["image/jpeg", "image/jpg", "image/png"];

    if (validExtensions.includes(fileType)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let fileURL = fileReader.result;
        let imgTag = `<img src=${fileURL} alt="" />`;
        dragArea.innerHTML = imgTag;
      };
      fileReader.readAsDataURL(file);
      setImage(file);
    } else {
      alert("Only JPG, JPEG, and PNG file supported");
    }
  };

  const handleUpload = (e) => {
    let file = e.target.files[0];
    setImage(file);
    displayFile(file);
  };

  const handleClick = () => {
    const uploadButton = document.querySelector(".upload-button");
    uploadButton.click();
  };

  useEffect(() => {
    setId(product._id);
    setName(product.name);
    setBrand(product.brand);
    setContractNumber(product.contractNumber);
    setPrice(product.price);
    setProductId(product.productId);
    setMinimumOrderSize(product.minimumOrderSize);
    setSupplierId({
      value: product.Supplier && product.Supplier._id,
      label: product.Supplier && product.Supplier.name,
    });
  }, [product]);

  useEffect(() => {
    dispatch(listSuppliers());
  }, [dispatch]);

  //populate suppliers
  const options = [];
  supplierList.forEach((sup) => {
    options.push({ value: sup._id, label: sup.name });
  });

  return (
    <Modal
      size="md"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">{`${
              Object.keys(product).length > 0 ? "Edit" : "Add"
            } Product`}</div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="">Upload Product Image</label>
              <div
                className="dropzone dropzone-default"
                id="kt_dropzone_1"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="dropzone-msg dz-message needsclick">
                  <h3 className="dropzone-msg-title" onClick={handleClick}>
                    Drop file here or click to upload.
                  </h3>
                  <input
                    type="file"
                    className="upload-button"
                    name="image"
                    hidden
                    onChange={handleUpload}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="">Product Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Product Brand Name</label>
              <input
                type="text"
                name="brand"
                className="form-control"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">HPRO Item Number</label>
              <input
                type="text"
                name="productId"
                className="form-control"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Contract Number</label>
              <input
                type="text"
                name="contractNumber"
                className="form-control"
                value={contractNumber}
                onChange={(e) => setContractNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Price</label>
              <input
                type="text"
                name="contractNumber"
                className="form-control"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Minimum Order Size</label>
              <input
                type="number"
                name="contractNumber"
                className="form-control"
                value={minimumOrderSize}
                onChange={(e) => setMinimumOrderSize(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Assign Supplier</label>
              {/* <select
                className="form-control selectpicker"
                name="supplierId"
                value={supplierId || ""}
                defaultValue={supplierId || ""}
                onChange={(e) => setSupplierId(e.target.value)}
              >
                <option value="">Select Supplier</option>
                {supplierList.map((sup) => {
                  return (
                    <option
                      key={sup._id}
                      value={`${sup._id}`}
                    >{`${sup.name}`}</option>
                  );
                })}
              </select> */}
              <Select
                defaultValue={supplierId}
                value={supplierId}
                onChange={setSupplierId}
                options={options}
              />
            </div>
            <div className="card-footer border-0 text-right">
              <button
                className="btn btn-outline-dark btn-pill px-6"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>{" "}
              <button className="btn btn-gradient btn-pill px-6" type="submit">
                {product._id ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateProduct;
