import { STOCK_TYPES } from "../actions/stockAction";

const initialState = {
  stocks: [],
  total: 0,
  stockRequest: {},
  stockTransferRequest: {},
  stock: {},
  stockRequests: [],
  totalStockRequests: 0,
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_TYPES.GET_STOCK:
      return {
        ...state,
        total: action.payload.total,
        stocks: action.payload.stocks,
      };

    case STOCK_TYPES.GET_STOCK_REQUESTS:
      return {
        ...state,
        stockRequests: action.payload.stockRequests,
        totalStockRequests: action.payload.total,
      };

    case STOCK_TYPES.UPDATE_STOCK:
      return {
        ...state,
        stocks: state.stocks.map((stock) => {
          if (stock._id === action.payload.stockId) return action.payload.stock;
          return stock;
        }),
      };

    case STOCK_TYPES.GET_SINGLE_STOCK:
      return {
        ...state,
        stock: action.payload,
      };

    case STOCK_TYPES.GET_STOCK_REQUEST:
      return {
        ...state,
        stockRequest: action.payload,
      };

    case STOCK_TYPES.GET_STOCK_TRANSFER_REQUEST:
      return {
        ...state,
        stockTransferRequest: action.payload,
      };

    case STOCK_TYPES.APPROVE_STOCK_TRANSFER_REQUEST:
      return {
        ...state,
        stockTransferRequest: {
          ...state.stockTransferRequest,
          status: "APPROVED",
        },
      };

    case STOCK_TYPES.DENY_STOCK_TRANSFER_REQUEST:
      return {
        ...state,
        stockTransferRequest: {
          ...state.stockTransferRequest,
          status: "DENIED",
        },
      };

    case STOCK_TYPES.UPDATE_STOCK_REQUEST:
      return {
        ...state,
        stocks: state.stocks.map((stock) => {
          if (stock._id === action.payload.stockRequestId)
            return { ...stock, status: action.payload.status };
          return { ...stock };
        }),
      };

    case STOCK_TYPES.UPDATE_STOCK_REQUEST_STATUS:
      return {
        ...state,
        stockRequest: { ...state.stockRequest, status: action.payload },
      };

    case STOCK_TYPES.UPDATE_STOCK_STATUS:
      return {
        ...state,
        stockRequests: state.stockRequests.map((stock) => {
          if (stock._id === action.payload.stockRequestId)
            return { ...stock, status: action.payload.status };
          return { ...stock };
        }),
      };

    case STOCK_TYPES.ADD_COMMENT:
      return {
        ...state,
        stockRequest: {
          ...state.stockRequest,
          StockRequestComments: [
            action.payload.comment,
            ...state.stockRequest.StockRequestComments,
          ],
        },
      };

    case STOCK_TYPES.APPROVE_SUGGESTED_STOCKS:
      return {
        ...state,
        stockRequests: state.stockRequests.map((request) => {
          if (request._id === action.payload)
            return { ...request, status: "ASSIGN_APPROVED" };
          return { ...request };
        }),
      };

    default:
      return state;
  }
};

export default stockReducer;
