import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationList } from "../../../redux/actions/locationAction";
import StockRow from "./StockRow";
import Modal from "react-bootstrap/Modal";
import {
  assignStock,
  replaceStock,
} from "../../../redux/actions/stockSupplierAction";

const ReplaceStockModal = ({
  stockId,
  stockUnits,
  minimumExpiry,
  modalShow,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { locationList } = useSelector((state) => state.location);

  let initialState;
  if (stockUnits.length === 0) {
    initialState = [
      { locationId: "", assignedUnits: 0, expiry: "", lotNumber: "" },
    ];
  } else {
    initialState = stockUnits.map((stockUnit) => {
      return {
        locationId: stockUnit.locationId,
        assignedUnits: stockUnit.assignedUnits,
        expiry: stockUnit.expiry.split("T")[0],
        lotNumber: stockUnit.lotNumber,
      };
    });
  }

  const [stock, setStock] = useState(initialState);
  console.log(initialState);

  const addRow = () => {
    const rowsInput = {
      locationId: "",
      assignedUnits: 0,
      expiry: "",
      lotNumber: "",
    };
    setStock([...stock, rowsInput]);
  };

  const handleDelete = (index) => {
    const rows = [...stock];
    rows.splice(index, 1);
    setStock(rows);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...stock];
    rowsInput[index][name] = value;
    setStock(rowsInput);
  };

  const handleSubmit = () => {
    dispatch(replaceStock(stockId, stock, minimumExpiry));
    handleClose();
  };

  useEffect(() => {
    dispatch(getLocationList());
  }, [dispatch]);
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Add Location
              <small>Choose location below from list</small>
            </div>
          </div>
          <div className="card-toolbar">
            <button className="btn btn-link font-weight-500" onClick={addRow}>
              + Add More Location
            </button>
          </div>
        </div>
        <div className="card-body">
          <StockRow
            stock={stock}
            handleChange={handleChange}
            handleDelete={handleDelete}
            locationList={locationList}
          />
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-gradient btn-pill px-8"
            data-dismiss="modal"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReplaceStockModal;
