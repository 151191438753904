import { getDataAPI, patchDataAPI, postDataAPI } from "../../utils/fetchData";
import { validateMinimumExpiry } from "../../utils/functions";
import TYPES from "../constants";

export const STOCK_REPLENISH_TYPES = {
  GET_REPLENISH: "GET_REPLENISH",
  REMOVE_REPLENISHED_STOCK: "REMOVE_REPLENISHED_STOCK",
  GET_REPLENISH_REQUEST: "GET_REPLENISH_REQUEST",
  POST_COMMENT: "POST_COMMENT",
  UPDATE_STATUS: "UPDATE_STATUS",
  UPDATE_REPLENISH_REQUEST: "UPDATE_REPLENISH_REQUEST",
  UPDATE_STOCK_STATUS: "UPDATE_STOCK_STATUS",
};

export const getStockReplenishRequests =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stockreplenishments?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.GET_REPLENISH,
          payload: {
            stockReplenish: res.data.data.requests,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const replenishStock =
  (stockRequestId, stock, minimumExpiry) => async (dispatch, getState) => {
    try {
      let error = false;
      console.log(stock);
      stock.forEach((s) => {
        if (
          !s.locationId ||
          !s.lotNumber ||
          !s.expiry ||
          s.assignedUnits <= 0
        ) {
          error = true;
        }
      });

      if (error) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }

      if (validateMinimumExpiry(stock, minimumExpiry)) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Assigned stock does not meet minimum expiration value",
          },
        });
        return;
      }

      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stockreplenish/${stockRequestId}/replenish`,
        { stocks: stock },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.UPDATE_REPLENISH_REQUEST,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: res.data.message },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getStockReplenishRequest =
  (stockReplenishId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stockreplenishment/${stockReplenishId}`,
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.GET_REPLENISH_REQUEST,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const addComment =
  (stockReplenishId, comment) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stockreplenish/${stockReplenishId}/comment`,
        { comment },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.POST_COMMENT,
          payload: res.data.data,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const updateStatus =
  (stockReplenishId, status) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stockreplenish/${stockReplenishId}/status`,
        { status },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.UPDATE_STATUS,
          payload: status,
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const assignStocksPermanent =
  (stockReplenishId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      const res = await patchDataAPI(
        `${usertype}/stockreplenish/${stockReplenishId}/paid`,
        {},
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_REPLENISH_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockReplenishId, status: "COMPLETED" },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
      return Promise.reject();
    }
  };
