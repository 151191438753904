import { PRODUCT_TYPES } from "../actions/supplierProductAction";

const initialState = {
  total: 0,
  products: [],
};

const supplierProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPES.GET_PRODUCTS:
      return {
        ...state,
        total: action.payload.total,
        products: action.payload.products,
      };
    default:
      return state;
  }
};

export default supplierProductReducer;
