import React, { useState } from "react";
import { BASE_URL } from "../../utils/fetchData";
import AssignStockModal from "./modals/AssignStockModal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  approveStockRequest,
  assignStocksPermanent,
  denyStockRequest,
} from "../../redux/actions/stockAction";

const SingleStock = ({ stock }) => {
  const {
    _id,
    parLevel,
    requestedParLevel,
    minimumExpiry,
    status,
    Member: { name: memberName, email, profilePic },
    Stock: {
      totalAssignedUnits,
      Product: { productId, name: productName, brand, image, contractNumber },
    },
    StockRequestUnits,
    invoiceId,
  } = stock;

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="card card-custom card-border productCard rounded-0 gutter-b">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex align-items-center flex-wrap">
              <div className="text-center mr-3">
                <div className="symbol symbol-120 bg-light">
                  <img
                    src={`${BASE_URL}/${image}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="color-secondary">Product Id: {productId}</div>
              </div>
              <div>
                <div className="productName">{productName.toUpperCase()}</div>
                <div className="productBrand">{brand}</div>
                <div className="d-flex align-items-center">
                  <div className="color-primary font-weight-500">
                    Par Level: {parLevel}
                  </div>
                  <div className="mx-2 color-primary">|</div>
                  <div className="color-success">Active</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-between align-items-stretch h-100 flex-column">
              <div className="d-flex align-items-center flex-wrap">
                <div className="text-center mr-3">
                  <div className="symbol symbol-60 bg-light p-3">
                    <img
                      src={
                        profilePic
                          ? `${BASE_URL}/${profilePic}`
                          : "assets/media/users/default.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div>
                  <div className="productName">{memberName}</div>
                  <div className="productBrand">{email}</div>
                </div>
              </div>
              <div className="d-flex align-items-start justify-content-start flex-column">
                <div className="color-primary font-weight-500">
                  Contract Number: {contractNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer py-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column align-items-center">
            <span className="color-primary font-weight-500">
              Requested Par Level: {requestedParLevel}
            </span>
            <span className="color-primary font-weight-500">
              Requested Expiry: {minimumExpiry} months
            </span>
            <span className="color-primary font-weight-500">
              {requestedParLevel - totalAssignedUnits > 0
                ? `Units to be Assigned: ${
                    requestedParLevel - totalAssignedUnits
                  } `
                : requestedParLevel - totalAssignedUnits > 0
                ? `Units to be Removed: ${
                    totalAssignedUnits - requestedParLevel
                  }`
                : ""}
            </span>
          </div>
          <div>
            {(status === "ASSIGNED_REQUEST" ||
              status === "ASSIGN_APPROVED" ||
              status === "ASSIGNED") && (
              <button
                className="btn btn-success px-8 mr-5 btn-pill"
                onClick={() => navigate(`/stockrequests/${_id}/comments`)}
              >
                Comments
              </button>
            )}

            {(status === "ASSIGNED_REQUEST" || status === "APPROVED") && (
              <button
                className="btn btn-gradient btn-pill px-8 mr-5"
                onClick={() => setShowModal(true)}
              >
                {status === "ASSIGNED_REQUEST" ? "Edit" : "Assign"}
              </button>
            )}
            {requestedParLevel > parLevel &&
              (status === "ASSIGNED_REQUEST" ||
                status === "ASSIGN_APPROVED" ||
                status === "ASSIGNED") && (
                <button
                  className="btn btn-blue btn-pill px-8 mr-5"
                  onClick={() => navigate("/invoice", { state: invoiceId })}
                >
                  Invoice
                </button>
              )}

            {requestedParLevel < parLevel ? (
              status === "ASSIGN_APPROVED" ? (
                <button
                  className="btn btn-blue btn-pill px-8 mr-5"
                  onClick={() => dispatch(assignStocksPermanent(_id))}
                >
                  Mark Assigned
                </button>
              ) : (
                <></>
              )
            ) : status === "ASSIGNED" ? (
              <label style={{ color: "green" }}>Paid</label>
            ) : status === "ASSIGN_APPROVED" ? (
              <button
                className="btn btn-blue btn-pill px-8 mr-5"
                onClick={() => dispatch(assignStocksPermanent(_id))}
              >
                Mark as Paid
              </button>
            ) : status === "PENDING" ||
              status === "APPROVED" ||
              status === "DENIED" ? (
              <></>
            ) : (
              <label style={{ color: "red" }}>Not Paid</label>
            )}

            {status === "PENDING" && (
              <>
                <button
                  className="btn btn-blue btn-pill px-8 mr-5"
                  onClick={() => dispatch(approveStockRequest(_id))}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger btn-pill px-8"
                  onClick={() => dispatch(denyStockRequest(_id))}
                >
                  Deny
                </button>
              </>
            )}

            {status === "DENIED" && (
              <label style={{ color: "red" }}>Rejected</label>
            )}
          </div>
        </div>
      </div>

      <AssignStockModal
        requestId={_id}
        stockUnits={StockRequestUnits}
        minimumExpiry={minimumExpiry}
        modalShow={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default SingleStock;
