import moment from "moment";

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validateMinimumExpiry = (stock, minimumExpiry) => {
  let error = false;
  stock.forEach((s) => {
    console.log(moment(s.expiry, "YYYY-MM-DD").diff(moment(), "months", true));
    if (
      moment(s.expiry, "YYYY-MM-DD").diff(moment(), "months", true) <
      minimumExpiry
    )
      error = true;
  });

  return error;
};
