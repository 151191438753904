import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { AddWarehouses } from "../../../redux/actions/supplychainAction";

const AddWarehouseModal = ({ productId, modalShow, handleClose }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name,
      line1,
      line2,
      postalCode,
    };
    dispatch(AddWarehouses(productId, payload));
    handleClose();

    setName("");
    setLine1("");
    setLine2("");
    setPostalCode("");
  };

  return (
    <Modal
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="card card-custom">
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Add Warehouses/ Distribution Centres
              <small>Enter below details to add</small>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Warehouses/ Distribution Centres"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 1"
              onChange={(e) => setLine1(e.target.value)}
              value={line1}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 2"
              onChange={(e) => setLine2(e.target.value)}
              value={line2}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Postal Code"
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </div>
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
          >
            Cancel
          </button>{" "}
          <button
            className="btn btn-gradient btn-pill px-8"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddWarehouseModal;
