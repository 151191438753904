import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { editLocation } from "../../../redux/actions/locationAction";

const EditLocationModal = ({
  id: d_id,
  name: d_name,
  line1: d_line1,
  line2: d_line2,
  postalCode: d_postalCode,
  comment: d_comment,
  modalShow,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const [id, setId] = useState(d_id);
  const [name, setName] = useState(d_name);
  const [line1, setLine1] = useState(d_line1);
  const [line2, setLine2] = useState(d_line2);
  const [postalCode, setPostalCode] = useState(d_postalCode);
  const [comment, setComment] = useState(d_comment);

  useEffect(() => {
    setId(d_id);
    setName(d_name);
    setLine1(d_line1);
    setLine2(d_line2);
    setPostalCode(d_postalCode);
    setComment(d_comment);
  }, [d_id, d_name, d_line1, d_line2, d_postalCode, d_comment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(id, name, line1, line2, postalCode, comment);

    const payload = {
      id,
      name,
      line1,
      line2,
      postalCode,
      comment,
    };

    dispatch(editLocation(payload));

    setId("");
    setName("");
    setLine1("");
    setLine2("");
    setPostalCode("");
    setComment("");

    handleClose();
  };

  return (
    <Modal
      size="md"
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <form className="card card-custom" onSubmit={handleSubmit}>
        <div className="card-header border-0">
          <div className="card-title">
            <div className="card-label">
              Edit Location
              <small>Update below details</small>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Location Name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              defaultValue={name || ""}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 1"
              name="line1"
              onChange={(e) => setLine1(e.target.value)}
              defaultValue={line1 || ""}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Address Line 2"
              name="line2"
              onChange={(e) => setLine2(e.target.value)}
              defaultValue={line2 || ""}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-rounded"
              placeholder="Postal Code"
              name="postalCode"
              onChange={(e) => setPostalCode(e.target.value)}
              defaultValue={postalCode || ""}
            />
          </div>
          <div className="form-group">
            <textarea
              name="comment"
              id=""
              cols="30"
              rows="10"
              className="form-control form-control-rounded"
              placeholder="Comment"
              onChange={(e) => setComment(e.target.value)}
              defaultValue={comment || ""}
            ></textarea>
          </div>
        </div>
        <div className="card-footer border-0 text-right">
          <button
            className="btn btn-outline-dark btn-pill px-8"
            onClick={handleClose}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-gradient btn-pill px-8"
            type="submit"
            // data-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditLocationModal;
