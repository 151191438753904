import { DASHBOARD_TYPES } from "../actions/dashboardAction";

const initialState = {
  orders: [],
  totalOrders: 0,
  totalMembers: 0,
  totalProducts: 0,
  totalSureSupply: 0,
  totalSuppliers: 0,
  totalDistributors: 0,
  totalLocations: 0,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_TYPES.GET_DATA:
      return {
        ...state,
        orders: action.payload.orders,
        totalOrders: action.payload.totalOrders,
        totalMembers: action.payload.totalMembers,
        totalProducts: action.payload.totalProducts,
        totalSureSupply: action.payload.totalSureSupply,
        totalSuppliers: action.payload.totalSuppliers,
        totalDistributors: action.payload.totalDistributors,
        totalLocations: action.payload.totalLocations,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
