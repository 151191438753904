import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/common/Pagination";
import SingleOrderHealthpro from "../../components/order/SingleOrderHealthpro";
import { getOrders } from "../../redux/actions/orderAction";

const Order = () => {
  const dispatch = useDispatch();
  const { total, orders } = useSelector((state) => state.order);

  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("newest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchTerm === "")
      dispatch(getOrders("", sort, rowsPerPage, page * rowsPerPage));
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm !== "")
        dispatch(getOrders(searchTerm, sort, rowsPerPage, page * rowsPerPage));
    }, 1000);

    return () => clearTimeout(getData);
  }, [dispatch, searchTerm, sort, page, rowsPerPage]);
  return (
    <div className="d-flex flex-column-fluid">
      {/* <!--begin::Container--> */}
      <div className="container">
        {/* <!--begin::Row--> */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">
                    Order{" "}
                    <small>
                      Total {total} {total === 1 ? "Order" : "Orders"}
                    </small>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="form-group m-0 mr-2">
                    <input
                      type="text"
                      className="form-control form-control-solid pl-4"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className="mr-2">
                    <select
                      className="form-control form-control-solid"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="newest">Newest</option>
                      <option value="oldest">Oldest</option>
                      <option value="name">By Name</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-vertical-center expiringTable">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ minWidth: "100px" }}>OrderID</th>
                        <th style={{ minWidth: "300px" }}>Supplier</th>
                        <th style={{ minWidth: "300px" }}>Drug</th>
                        <th style={{ minWidth: "300px" }}>Member</th>
                        <th style={{ minWidth: "100px" }}>Lot No.</th>
                        <th style={{ minWidth: "100px" }}>Order Date</th>
                        <th style={{ minWidth: "300px" }}>Location</th>
                        <th style={{ minWidth: "100px" }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order) => {
                        return (
                          <SingleOrderHealthpro key={order._id} order={order} />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  count={total}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end::Container--> */}
    </div>
  );
};

export default Order;
