import React from "react";
import { BASE_URL } from "../../utils/fetchData";

const ViewProductDistributor = ({ product }) => {
  const {
    productId,
    name: productName,
    brand,
    image: productImage,
    price,
    Supplier: supplier,
  } = product;

  return (
    <tr>
      <td>{productId}</td>
      <td>
        <div className="d-flex align-items-center flex-wrap">
          <div className="text-center mr-3">
            <div className="symbol symbol-50 bg-light">
              <img
                src={
                  productImage
                    ? `${BASE_URL}/${productImage}`
                    : "assets/img/med-1.png"
                }
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div>
            <div className="font-size-lg font-weight-boldest color-primary">
              {productName}
            </div>
            <div className="font-size-sm font-weight-bold color-primary">
              {brand}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                supplier.profilePic
                  ? `${BASE_URL}/${supplier.profilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {supplier.name}
            </div>
            <div className="color-secondary">{supplier.email}</div>
          </div>
        </div>
      </td>
      <td>{supplier.address}</td>
      <td>{price && price.toFixed(2)}</td>
    </tr>
  );
};

export default ViewProductDistributor;
