import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/actions/productAction";
import { BASE_URL } from "../../utils/fetchData";
import ViewSupplier from "./modals/ViewSupplier";

const ViewProduct = ({ product, handleEditProduct }) => {
  const {
    _id: id,
    productId,
    name: productName,
    brand,
    image: productImage,
    price,
    contractNumber,
    minimumOrderSize,
    Supplier: supplier,
  } = product;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    dispatch(deleteProduct(id));
  };

  return (
    <tr>
      <td>{productId}</td>
      <td>
        <div className="product-view">
          <div className="text-center mr-3 w-15">
            <div className="symbol symbol-50 bg-light">
              <img
                src={
                  productImage
                    ? `${BASE_URL}/${productImage}`
                    : "assets/img/med-1.png"
                }
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="w-85">
            <div className="font-size-lg font-weight-boldest color-primary">
              {productName}
            </div>
            <div className="font-size-sm font-weight-bold color-primary">
              {brand}
            </div>
          </div>
        </div>
      </td>
      <td>{contractNumber}</td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                supplier.profilePic
                  ? `${BASE_URL}/${supplier.profilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {supplier.name}
            </div>
            <div className="color-secondary">{supplier.email}</div>
          </div>
        </div>
      </td>
      <td>{price && price.toFixed(2)}</td>
      <td>{minimumOrderSize}</td>
      <td>
        <div className="product-view">
          <button
            className="btn btn-xs btn-light btn-icon"
            onClick={() => handleEditProduct(product)}
          >
            <i className="fas fa-pencil-alt color-primary"></i>
          </button>
          <button
            className="btn btn-xs btn-light btn-icon"
            onClick={handleDelete}
          >
            <i className="fas fa-trash-alt color-primary"></i>
          </button>
        </div>
      </td>
      <ViewSupplier
        supplier={supplier}
        modalShow={showModal}
        handleClose={() => setShowModal(false)}
      />
    </tr>
  );
};

export default ViewProduct;
