import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { dispatchOrder } from "../../redux/actions/orderAction";
import { BASE_URL } from "../../utils/fetchData";

const SingleExpiringOrder = ({ order }) => {
  let {
    _id: orderId,
    status,
    units,
    lotNumber,
    unitsExpiry: expiry,
    isApproved,
    Member: { name: memberName, email: memberEmail, profilePic },
    Stock: {
      Product: { name: productName, brand, image: productImage },
    },
    Location: { line1, line2, postalCode },
  } = order;

  expiry = moment(expiry).format("MMM DD, YYYY");

  const dispatch = useDispatch();

  const handleDispatch = () => {
    dispatch(dispatchOrder(orderId, "EXPIRING"));
  };

  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={`${BASE_URL}/${productImage}`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {productName}
            </div>
            <div className="color-secondary">{brand}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={`${BASE_URL}/${profilePic}`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {memberName}
            </div>
            <div className="color-secondary">{memberEmail}</div>
          </div>
        </div>
      </td>
      <td>{lotNumber}</td>
      <td>{expiry}</td>
      <td>{`${line1}, ${line2}, ${postalCode}`}</td>
      <td>{units}</td>
      <td>
        <span
          className={`${
            status === "COMPLETED"
              ? "badge rounded-pill badge-success"
              : "badge rounded-pill badge-primary"
          }`}
        >
          {isApproved && status === "CREATED" ? (
            <button className="btn btn-blue" onClick={handleDispatch}>
              Ship
            </button>
          ) : isApproved && status === "PACKAGED" ? (
            "Waiting for confirmation"
          ) : isApproved && status === "COMPLETED" ? (
            "Completed"
          ) : (
            "Waiting for member approval"
          )}
        </span>
      </td>
    </tr>
  );
};

export default SingleExpiringOrder;
