import React from "react";
import { Link } from "react-router-dom";

const HeaderMenu = () => {
  return (
    <div
      id="kt_header_menu"
      className="header-menu header-menu-mobile header-menu-layout-default"
    >
      {/* <!--begin::Header Nav--> */}
      <ul className="menu-nav">
        <li className="menu-item">
          {/* <!--begin::Toggle--> */}
          <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
            <img src="assets/img/menu.svg" alt="" />
          </button>
          {/* <!--end::Toolbar--> */}
        </li>
        <li className="menu-item">
          <Link to="/sure-supply-shield" className="menu-link">
            <span className="menu-text">Reserve Drug Supply</span>
            <i className="menu-arrow"></i>
          </Link>
        </li>
      </ul>
      {/* <!--end::Header Nav--> */}
    </div>
  );
};

export default HeaderMenu;
