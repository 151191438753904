import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);

  if (!token) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  } else {
    const decode = JSON.parse(atob(token.split(".")[1]));
    if (decode.exp * 1000 < new Date().getTime()) {
      return <Navigate to="/login" />;
    }
  }

  // authorized so return child components
  return children;
};

export default PrivateRoute;
