import { INVOICE_TYPES } from "../actions/invoiceAction";

const initialState = {
  invoice: {},
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_TYPES.GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
