import TYPES from "../constants";

const initialState = {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.AUTH:
      return action.payload;
    case TYPES.LOGOUT:
      return action.payload;
    case TYPES.EDIT_PROFILE:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default authReducer;
