import moment from "moment";
import { BASE_URL } from "../../utils/fetchData";

const Comment = ({ comment, member, supplier }) => {
  const user = comment.senderType === "MEMBER" ? member : supplier;
  return (
    <div className="comment">
      <div className="user-avatar">
        <img
          src={
            user.profilePic
              ? `${BASE_URL}/${user.profilePic}`
              : "assets/media/users/default.jpg"
          }
          alt="User Avatar"
        />
      </div>
      <div className="comment-content">
        <p className="username">{user.name}</p>
        <p className="timestamp">
          {comment.date && moment(comment.date).format("MMM DD, YYYY HH:mm")}
        </p>
        <p className="comment-text">{comment.comment}</p>
      </div>
    </div>
  );
};

export default Comment;
