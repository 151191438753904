import { STOCK_SUPPLIER_TYPES } from "../actions/stockSupplierAction";

const initialState = {
  stocks: [],
  total: 0,
  totalAggregated: 0,
  assignedStocks: [],
  assignedStocksAggregated: [],
  totalAssignedStocks: 0,
};

const stockSupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_SUPPLIER_TYPES.GET_STOCK:
      return {
        ...state,
        total: action.payload.total,
        stocks: action.payload.stocks,
      };

    case STOCK_SUPPLIER_TYPES.FILTER_STOCK:
      return {
        ...state,
        total: state.total - 1,
        stocks: state.stocks.filter((s) => s._id !== action.payload),
      };

    case STOCK_SUPPLIER_TYPES.GET_ASSIGNED_STOCK:
      return {
        ...state,
        totalAssignedStocks: action.payload.total,
        assignedStocks: action.payload.stocks,
      };

    case STOCK_SUPPLIER_TYPES.GET_ASSIGNED_STOCK_AGGREGATE:
      return {
        ...state,
        totalAggregated: action.payload.total,
        assignedStocksAggregated: action.payload.stocks,
      };

    case STOCK_SUPPLIER_TYPES.UPDATE_ASSIGNED_STOCK:
      return {
        ...state,
        assignedStocks: state.assignedStocks.map((s) => {
          if (s._id === action.payload.stockId) return action.payload.stock;
          return s;
        }),
      };

    case STOCK_SUPPLIER_TYPES.UPDATE_STOCK_STATUS:
      return {
        ...state,
        stocks: state.stocks.map((stock) => {
          if (stock._id === action.payload.stockRequestId)
            return { ...stock, status: action.payload.status };
          return { ...stock };
        }),
      };
    default:
      return state;
  }
};

export default stockSupplierReducer;
