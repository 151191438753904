import {
  getDataAPI,
  formDataPostAPI,
  deleteDataAPI,
  formDataPatchAPI,
  postDataAPI,
} from "../../utils/fetchData";
import TYPES from "../constants";

export const DISTRIBUTOR_TYPES = {
  CREATE_DISTRIBUTOR: "CREATE_DISTRIBUTOR",
  EDIT_DISTRIBUTOR: "EDIT_DISTRIBUTOR",
  GET_DISTRIBUTORS: "GET_DISTRIBUTORS",
  DELETE_DISTRIBUTOR: "DELETE_DISTRIBUTOR",
  GET_DISTRIBUTOR_NAMES: "GET_DISTRIBUTOR_NAMES",
};

export const getDistributors =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/distributors?search=${search}&sort=${sort}&limit=${limit}&skip=${skip}`,
        token
      );

      if (res.data.success) {
        dispatch({
          type: DISTRIBUTOR_TYPES.GET_DISTRIBUTORS,
          payload: {
            distributors: res.data.data.distributors,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const createDistributor = (payload) => async (dispatch, getState) => {
  try {
    if (
      !payload.image ||
      !payload.name ||
      !payload.email ||
      !payload.phone ||
      !payload.address
    ) {
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: "Please fill required fields",
        },
      });
      return;
    }
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPostAPI(
      `${usertype}/distributor/register`,
      formData,
      token
    );

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: DISTRIBUTOR_TYPES.CREATE_DISTRIBUTOR,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const editDistributor = (payload) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;

    const formData = new FormData();
    formData.append("profilePic", payload.image);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("phone", payload.phone);
    formData.append("address", payload.address);
    formData.append("isActive", payload.isActive);

    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await formDataPatchAPI(
      `${usertype}/distributor/${payload.id}`,
      formData,
      token
    );

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: DISTRIBUTOR_TYPES.EDIT_DISTRIBUTOR,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const deleteDistributor = (id) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await deleteDataAPI(`${usertype}/distributor/${id}`, token);

    console.log(res.data.data);
    if (res.data.success) {
      dispatch({
        type: DISTRIBUTOR_TYPES.DELETE_DISTRIBUTOR,
        payload: id,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const getDistributorNames = () => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/distributor`, token);

    if (res.data.success) {
      dispatch({
        type: DISTRIBUTOR_TYPES.GET_DISTRIBUTOR_NAMES,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};

export const selectDistributors =
  (distributorIds) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/distributor`,
        { distributorIds },
        token
      );

      if (res.data.success) {
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };
