import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import { getOrder } from "../../redux/actions/orderAction";
import { BASE_URL } from "../../utils/fetchData";

const ProcessedOrderDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  useEffect(() => {
    const getData = () => dispatch(getOrder(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);
  return (
    <>
      {Object.keys(order).length === 0 && <Loading />}
      {Object.keys(order).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            {/* <!--begin::Dashboard--> */}
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">Order No . {order.orderId}</div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-custom card-stretch card-border productCard rounded-0 gutter-b">
                      <div className="card-body p-3">
                        <h5 className="font-weight-boldest color-primary mb-10">
                          Member Details
                        </h5>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-80 bg-light">
                              <img
                                src={
                                  order.Member.profilePic
                                    ? `${BASE_URL}/${order.Member.profilePic}`
                                    : "/assets/media/users/default.jpg"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="productName">
                              {order.Member.name}
                            </div>
                            <div className="productBrand">
                              {order.Member.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-custom card-border card-stretch productCard rounded-0 gutter-b">
                      <div className="card-body p-3">
                        <h5 className="font-weight-boldest color-primary mb-10">
                          Product Details
                        </h5>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-120 bg-light">
                              <img
                                src={
                                  order.Stock.Product.image
                                    ? `${BASE_URL}/${order.Stock.Product.image}`
                                    : "assets/img/med-1.png"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="color-secondary">
                              Product Id: {order.Stock.Product.productId}
                            </div>
                          </div>
                          <div>
                            <div className="productName">
                              {order.Stock.Product.name}
                            </div>
                            <div className="productBrand">
                              {order.Stock.Product.brand}
                            </div>
                            <div className="color-primary">
                              Units ordered: {order.units}
                            </div>
                            <div className="color-success font-size-h4 font-weight-boldest m-0">
                              {order.status === "PACKAGED"
                                ? "SHIPPED"
                                : "COMPLETED"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card card-custom card-border card-stretch productCard rounded-0 gutter-b">
                      <div className="card-body p-3">
                        <h5 className="font-weight-boldest color-primary mb-10">
                          Supplier Details
                        </h5>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-120 bg-light">
                              <img
                                src={
                                  order.Supplier.profilePic
                                    ? `${BASE_URL}/${order.Supplier.profilePic}`
                                    : "assets/media/users/default.jpg"
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div>
                            <div className="productName">
                              {order.Supplier.name}
                            </div>
                            <div className="productBrand">
                              {order.Supplier.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default ProcessedOrderDetail;
