import React from "react";

const Footer = () => {
  return (
    <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="text-dark order-2 order-md-1"></div>
      </div>
    </div>
  );
};

export default Footer;
