import { getDataAPI, patchDataAPI, postDataAPI } from "../../utils/fetchData";
import { validateMinimumExpiry } from "../../utils/functions";
import TYPES from "../constants";

export const STOCK_SUPPLIER_TYPES = {
  GET_STOCK: "GET_STOCK",
  FILTER_STOCK: "FILTER_STOCK",
  GET_ASSIGNED_STOCK: "GET_ASSIGNED_STOCK",
  GET_ASSIGNED_STOCK_AGGREGATE: "GET_ASSIGNED_STOCK_AGGREGATE",
  UPDATE_ASSIGNED_STOCK: "UPDATE_ASSIGNED_STOCK",
  UPDATE_STOCK_STATUS: "UPDATE_STOCK_STATUS",
};

export const getStocks =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stockrequests?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.GET_STOCK,
          payload: {
            stocks: res.data.data.stocks,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const assignStock =
  (stockRequestId, stock, minimumExpiry, noToast) =>
  async (dispatch, getState) => {
    try {
      let error = false;
      stock.forEach((s) => {
        if (
          !s.locationId ||
          !s.expiry ||
          !s.lotNumber ||
          s.assignedUnits <= 0
        ) {
          error = true;
        }
      });
      if (error) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }

      if (validateMinimumExpiry(stock, minimumExpiry)) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Assigned stock does not meet minimum expiration value",
          },
        });
        return;
      }

      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await patchDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/increase/approve`,
        { stocks: stock },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockRequestId, status: "ASSIGNED_REQUEST" },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        if (!noToast)
          dispatch({
            type: TYPES.ALERT,
            payload: { success: res.data.message },
          });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const replaceStock =
  (stockId, stock, minimumExpiry) => async (dispatch, getState) => {
    try {
      let error = false;
      stock.forEach((s) => {
        if (
          !s.locationId ||
          !s.expiry ||
          !s.lotNumber ||
          s.assignedUnits <= 0
        ) {
          error = true;
        }
      });
      if (error) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Please fill required fields",
          },
        });
        return;
      }

      if (validateMinimumExpiry(stock, minimumExpiry)) {
        dispatch({
          type: TYPES.ALERT,
          payload: {
            error: "Assigned stock does not meet minimum expiration value",
          },
        });
        return;
      }

      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await postDataAPI(
        `${usertype}/stock/${stockId}/assign`,
        { stocks: stock },
        token
      );

      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.UPDATE_ASSIGNED_STOCK,
          payload: { stockId, stock: res.data.data },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        dispatch({
          type: TYPES.ALERT,
          payload: { success: res.data.message },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getAssignedStock =
  (search, sort, limit, skip, filter) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stocks?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}&filter=${filter}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.GET_ASSIGNED_STOCK,
          payload: {
            stocks: res.data.data.stocks,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const getAssignedStockAggregate =
  (search, sort, limit, skip) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;
      dispatch({ type: TYPES.ALERT, payload: { loading: true } });
      const res = await getDataAPI(
        `${usertype}/stocks/aggregate?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`,
        token
      );
      console.log(res.data);

      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.GET_ASSIGNED_STOCK_AGGREGATE,
          payload: {
            stocks: res.data.data.stocks,
            total: res.data.data.total,
          },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
    }
  };

export const assignStocksPermanent =
  (stockRequestId) => async (dispatch, getState) => {
    try {
      const { usertype, token } = getState().auth;

      const res = await patchDataAPI(
        `${usertype}/stockrequest/${stockRequestId}/paid`,
        {},
        token
      );
      if (res.data.success) {
        dispatch({
          type: STOCK_SUPPLIER_TYPES.UPDATE_STOCK_STATUS,
          payload: { stockRequestId, status: "ASSIGNED" },
        });
        dispatch({ type: TYPES.ALERT, payload: { loading: false } });
        return Promise.resolve();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: TYPES.ALERT,
        payload: {
          error: err.response.data.message,
        },
      });
      return Promise.reject();
    }
  };
