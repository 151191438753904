import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import {
  approveLocation,
  denyLocation,
  getLocation,
} from "../../redux/actions/locationAction";
import { BASE_URL } from "../../utils/fetchData";

const LocationDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.location);

  const handleApprove = () => {
    dispatch(approveLocation(id));
  };

  const handleDeny = () => {
    dispatch(denyLocation(id));
  };

  useEffect(() => {
    const getData = () => dispatch(getLocation(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);

  console.log(location);

  return (
    <>
      {Object.keys(location).length === 0 && <Loading />}
      {Object.keys(location).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            {/* <!--begin::Dashboard--> */}
            <div className="card card-custom border-radius-18px">
              <div className="card-header border-0">
                <div className="card-title">
                  <div className="card-label">Location</div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          {location.supplierId ? "Supplier" : "Distributor"}{" "}
                          Name
                        </th>
                        <th>Location Name</th>
                        <th>Location</th>
                        <th>Comment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start">
                            <div className="symbol symbol-40 bg-light mr-2">
                              <img
                                src={
                                  location.supplierId &&
                                  location.Supplier.profilePic
                                    ? `${BASE_URL}/${location.Supplier.profilePic}`
                                    : location.distributorId &&
                                      location.Distributor.profilePic
                                    ? `${BASE_URL}/${location.Distributor.profilePic}`
                                    : "/assets/media/users/default.jpg"
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="font-weight-boldest font-size-lg color-primary">
                                {location.supplierId
                                  ? location.Supplier.name
                                  : location.Distributor.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{location.name}</td>
                        <td>{`${location.line1}, ${location.line2}, ${location.postalCode}`}</td>
                        <td>{location.comment}</td>
                        {location.status === "PENDING" ? (
                          <td>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={handleDeny}
                            >
                              Deny
                            </button>
                            <button
                              className="btn btn-sm btn-blue"
                              onClick={handleApprove}
                            >
                              Approve
                            </button>
                          </td>
                        ) : (
                          <td>
                            <span
                              className={`color-${
                                location.status === "DENIED"
                                  ? "warning"
                                  : "success"
                              }`}
                            >
                              {location.status}
                            </span>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default LocationDetail;
