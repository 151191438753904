import React from "react";
import { BASE_URL } from "../../utils/fetchData";
import { useNavigate } from "react-router";

const SingleReplenishMember = ({ stockRequest }) => {
  let {
    _id,
    units,
    status,
    Stock: {
      minimumExpiry,
      Product: { name: productName, brand: productBrand, image: productImage },
    },
    Supplier: { name: supplierName, email: supplierEmail, profilePic },
    invoiceId,
  } = stockRequest;

  const statusTabColor =
    status === "PENDING"
      ? "primary"
      : status === "ASSIGNED_REQUEST" || status === "APPROVED"
      ? "warning"
      : status === "DENIED"
      ? "danger"
      : "dark";

  const navigate = useNavigate();

  return (
    <tr>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={`${BASE_URL}/${productImage}`}
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {productName}
            </div>
            <div className="color-secondary">{productBrand}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-start">
          <div className="symbol symbol-40 bg-light mr-2">
            <img
              src={
                profilePic
                  ? `${BASE_URL}/${profilePic}`
                  : "assets/media/users/default.jpg"
              }
              alt=""
              className="img-fluid"
            />
          </div>
          <div>
            <div className="font-weight-boldest font-size-lg color-primary">
              {supplierName}
            </div>
            <div className="color-secondary">{supplierEmail}</div>
          </div>
        </div>
      </td>

      <td>{units}</td>
      <td>{`${minimumExpiry} ${minimumExpiry === 1 ? "month" : "months"}`}</td>
      <td>
        {" "}
        <span className={`badge rounded-pill badge-${statusTabColor}`}>
          {status}
        </span>
      </td>
      <td>
        {(status === "ASSIGNED_REQUEST" ||
          status === "APPROVED" ||
          status === "DENIED" ||
          status === "COMPLETED") && (
          <button
            className="btn btn-success"
            onClick={() => navigate(`/stockreplenishment/${_id}/comments`)}
          >
            Review
          </button>
        )}
      </td>
      {(status === "ASSIGNED_REQUEST" ||
        status === "APPROVED" ||
        status === "COMPLETED") && (
        <td>
          <button
            className="btn btn-blue"
            onClick={() => navigate("/invoice", { state: invoiceId })}
          >
            Invoice
          </button>
        </td>
      )}
    </tr>
  );
};

export default SingleReplenishMember;
