import React from "react";

const SingleSupplyChainInfo = ({ item }) => {
  const { name, line1, line2, postalCode, flag } = item;
  return (
    <div className="d-flex align-items-start">
      <div className="mr-3 mt-2">
        <i
          className={`fas fas fa-flag ${
            flag === "GREEN"
              ? "color-success"
              : flag === "RED"
              ? "text-danger"
              : "text-warning"
          }`}
        ></i>
      </div>
      <div>
        <div className="font-weight-boldest font-size-lg color-primary">
          {name}
        </div>
        <div className="color-primary">
          {`${line1}, ${line2}, ${postalCode}`}
        </div>
      </div>
    </div>
  );
};

export default SingleSupplyChainInfo;
