import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loading from "../../components/alert/Loading";
import {
  approveStockTransferRequest,
  denyStockTransferRequest,
  getStockTransferRequest,
} from "../../redux/actions/stockAction";
import { BASE_URL } from "../../utils/fetchData";

const TransferStockDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { stockTransferRequest } = useSelector((state) => state.stock);
  const { usertype } = useSelector((state) => state.auth);

  useEffect(() => {
    const getData = () => dispatch(getStockTransferRequest(id));
    getData();

    return () => clearTimeout(getData);
  }, [dispatch, id]);

  const handleApproveClick = () => {
    dispatch(approveStockTransferRequest(id));
  };
  const handleDenyClick = () => {
    dispatch(denyStockTransferRequest(id));
  };
  return (
    <>
      {Object.keys(stockTransferRequest).length === 0 && <Loading />}
      {Object.keys(stockTransferRequest).length > 0 && (
        <div className="d-flex flex-column-fluid">
          {/* <!--begin::Container--> */}
          <div className="container">
            <div className="card card-custom border-radius-18px gutter-b">
              <div className="card-header border-0 align-items-center">
                <div className="card-title">
                  <div className="card-label">
                    {stockTransferRequest.senderMember.name}
                  </div>
                </div>
                <div>
                  <div className="text-center font-weight-boldest color-success font-size-lg">
                    Transfer Inventory
                    <br />
                    <img
                      src="/assets/img/dashed-right-green-arrow.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="card-title">
                  <div className="card-label">
                    {stockTransferRequest.recipientMember.name}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="card card-custom card-border productCard rounded-0 gutter-b">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="text-center mr-3">
                            <div className="symbol symbol-120 bg-light">
                              <img
                                src={`${BASE_URL}/${stockTransferRequest.Stock.Product.image}`}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="color-secondary">
                              Product Id:{" "}
                              {stockTransferRequest.Stock.Product.productId}
                            </div>
                          </div>
                          <div>
                            <div className="productName">
                              {stockTransferRequest.Stock.Product.name}
                            </div>
                            <div className="productBrand">
                              {stockTransferRequest.Stock.Product.brand}
                            </div>
                            <div className="color-success">
                              Par Level: {stockTransferRequest.Stock.parLevel}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between flex-column">
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="text-center mr-3">
                              <div className="symbol symbol-80 bg-light">
                                <img
                                  src={
                                    stockTransferRequest.Supplier &&
                                    stockTransferRequest.Supplier.profilePic
                                      ? `${BASE_URL}/${stockTransferRequest.Supplier.profilePic}`
                                      : "assets/media/users/default.jpg"
                                  }
                                  alt=""
                                  className="img-fluid "
                                />
                              </div>
                            </div>
                            <div>
                              <div className="productName">
                                {stockTransferRequest.Supplier.name}
                              </div>
                              <div className="productBrand">
                                {stockTransferRequest.Supplier.address}
                              </div>
                              <div className="color-warning">
                                Contract No:{" "}
                                {
                                  stockTransferRequest.Stock.Product
                                    .contractNumber
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-5">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Location</th>
                              <th>Units</th>
                              <th>Lot Number</th>
                              <th>Expiry</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stockTransferRequest.StockTransferUnits.map(
                              (stockUnit, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{`${stockUnit.StockUnit.Location.line1}, ${stockUnit.StockUnit.Location.line2}, ${stockUnit.StockUnit.Location.postalCode}`}</td>
                                    <td>{stockUnit.units}</td>
                                    <td>{stockUnit.StockUnit.lotNumber}</td>
                                    <td>
                                      {stockUnit.StockUnit.expiry &&
                                        moment(
                                          stockUnit.StockUnit.expiry
                                        ).format("MMM DD, YYYY")}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {stockTransferRequest.status === "PENDING" &&
                usertype === "member" && (
                  <div className="card-footer text-right">
                    <button
                      className="btn btn-danger"
                      onClick={handleDenyClick}
                    >
                      Deny
                    </button>{" "}
                    <button
                      className="btn btn-blue"
                      onClick={handleApproveClick}
                    >
                      Approve
                    </button>
                  </div>
                )}
            </div>
          </div>
          {/* <!--end::Container--> */}
        </div>
      )}
    </>
  );
};

export default TransferStockDetail;
