import { getDataAPI } from "../../utils/fetchData";
import TYPES from "../constants";

export const INVOICE_TYPES = {
  GET_INVOICE: "GET_INVOICE",
};

export const getInvoice = (invoiceId) => async (dispatch, getState) => {
  try {
    const { usertype, token } = getState().auth;
    dispatch({ type: TYPES.ALERT, payload: { loading: true } });
    const res = await getDataAPI(`${usertype}/invoice/${invoiceId}`, token);

    if (res.data.success) {
      dispatch({
        type: INVOICE_TYPES.GET_INVOICE,
        payload: res.data.data,
      });
      dispatch({ type: TYPES.ALERT, payload: { loading: false } });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: TYPES.ALERT,
      payload: {
        error: err.response.data.message,
      },
    });
  }
};
