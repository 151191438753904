import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import MemberRoutes from "./routes/MemberRoutes";
import SupplierRoutes from "./routes/SupplierRoutes";
import Loading from "./components/alert/Loading";
import BasicRoutes from "./routes/BasicRoutes";
import HealthproRoutes from "./routes/HealthproRoutes";
import DistributorRoutes from "./routes/DistributorRoutes";
import Alert from "./components/alert/Alert";

const loading = <Loading />;

function App() {
  const { auth } = useSelector((state) => state);

  return (
    <>
      <Alert />
      <BrowserRouter>
        <Suspense fallback={loading}>
          {auth.usertype === "member" ? (
            <MemberRoutes />
          ) : auth.usertype === "supplier" ? (
            <SupplierRoutes />
          ) : auth.usertype === "healthpro" ? (
            <HealthproRoutes />
          ) : auth.usertype === "distributor" ? (
            <DistributorRoutes />
          ) : (
            <BasicRoutes />
          )}
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
